import React from "react";

export default function BootstrapListGroup() {
  return (
    <div class="main-content">
      <section class="section">
        <div class="section-header">
          <h1>List Group</h1>
          <div class="section-header-breadcrumb">
            <div class="breadcrumb-item active">
              <a href="#">Dashboard</a>
            </div>
            <div class="breadcrumb-item">
              <a href="#">Bootstrap Components</a>
            </div>
            <div class="breadcrumb-item">List Group</div>
          </div>
        </div>

        <div class="section-body">
          <h2 class="section-title">List Group</h2>
          <p class="section-lead">
            List groups are a flexible and powerful component for displaying a
            series of content. Modify and extend them to support just about any
            content within.
          </p>

          <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
              <div class="card">
                <div class="card-header">
                  <h4>Basic</h4>
                </div>
                <div class="card-body">
                  <ul class="list-group">
                    <li class="list-group-item">Cras justo odio</li>
                    <li class="list-group-item">Dapibus ac facilisis in</li>
                    <li class="list-group-item">Morbi leo risus</li>
                    <li class="list-group-item">Porta ac consectetur ac</li>
                    <li class="list-group-item">Vestibulum at eros</li>
                  </ul>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h4>Disabled</h4>
                </div>
                <div class="card-body">
                  <ul class="list-group">
                    <li class="list-group-item">Cras justo odio</li>
                    <li class="list-group-item disabled">
                      Dapibus ac facilisis in
                    </li>
                    <li class="list-group-item">Morbi leo risus</li>
                    <li class="list-group-item">Porta ac consectetur ac</li>
                    <li class="list-group-item">Vestibulum at eros</li>
                  </ul>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h4>Flush</h4>
                </div>
                <div class="card-body">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">Cras justo odio</li>
                    <li class="list-group-item">Dapibus ac facilisis in</li>
                    <li class="list-group-item">Morbi leo risus</li>
                    <li class="list-group-item">Porta ac consectetur ac</li>
                    <li class="list-group-item">Vestibulum at eros</li>
                  </ul>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h4>Badges</h4>
                </div>
                <div class="card-body">
                  <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Cras justo odio
                      <span class="badge badge-primary badge-pill">14</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Dapibus ac facilisis in
                      <span class="badge badge-primary badge-pill">2</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Morbi leo risus
                      <span class="badge badge-primary badge-pill">1</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h4>JavaScript Behavior</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-4">
                      <div class="list-group" id="list-tab" role="tablist">
                        <a
                          class="list-group-item list-group-item-action active"
                          id="list-home-list"
                          data-toggle="list"
                          href="#list-home"
                          role="tab"
                        >
                          Home
                        </a>
                        <a
                          class="list-group-item list-group-item-action"
                          id="list-profile-list"
                          data-toggle="list"
                          href="#list-profile"
                          role="tab"
                        >
                          Profile
                        </a>
                        <a
                          class="list-group-item list-group-item-action"
                          id="list-messages-list"
                          data-toggle="list"
                          href="#list-messages"
                          role="tab"
                        >
                          Messages
                        </a>
                        <a
                          class="list-group-item list-group-item-action"
                          id="list-settings-list"
                          data-toggle="list"
                          href="#list-settings"
                          role="tab"
                        >
                          Settings
                        </a>
                      </div>
                    </div>
                    <div class="col-8">
                      <div class="tab-content" id="nav-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="list-home"
                          role="tabpanel"
                          aria-labelledby="list-home-list"
                        >
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur. Excepteur sint occaecat
                          cupidatat non.
                        </div>
                        <div
                          class="tab-pane fade"
                          id="list-profile"
                          role="tabpanel"
                          aria-labelledby="list-profile-list"
                        >
                          Deserunt cupidatat anim ullamco ut dolor anim sint
                          nulla amet incididunt tempor ad ut pariatur officia
                          culpa laboris occaecat. Dolor in nisi aliquip in non
                          magna amet nisi sed commodo proident anim deserunt
                          nulla veniam occaecat reprehenderit esse ut eu culpa
                          fugiat nostrud pariatur adipisicing incididunt
                          consequat nisi non amet.
                        </div>
                        <div
                          class="tab-pane fade"
                          id="list-messages"
                          role="tabpanel"
                          aria-labelledby="list-messages-list"
                        >
                          In quis non esse eiusmod sunt fugiat magna pariatur
                          officia anim ex officia nostrud amet nisi pariatur eu
                          est id ut exercitation ex ad reprehenderit dolore
                          nostrud sit ut culpa consequat magna ad labore
                          proident ad qui et tempor exercitation in aute veniam
                          et velit dolore irure qui ex magna ex culpa enim anim
                          ea mollit consequat ullamco exercitation in.
                        </div>
                        <div
                          class="tab-pane fade"
                          id="list-settings"
                          role="tabpanel"
                          aria-labelledby="list-settings-list"
                        >
                          Lorem ipsum culpa in ad velit dolore anim labore
                          incididunt do aliqua sit veniam commodo elit dolore do
                          labore occaecat laborum sed quis proident fugiat sunt
                          pariatur. Cupidatat ut fugiat anim ut dolore excepteur
                          ut voluptate dolore excepteur mollit commodo.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="card">
                <div class="card-header">
                  <h4>Active</h4>
                </div>
                <div class="card-body">
                  <ul class="list-group">
                    <li class="list-group-item">Cras justo odio</li>
                    <li class="list-group-item active">
                      Dapibus ac facilisis in
                    </li>
                    <li class="list-group-item">Morbi leo risus</li>
                    <li class="list-group-item">Porta ac consectetur ac</li>
                    <li class="list-group-item">Vestibulum at eros</li>
                  </ul>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h4>Link</h4>
                </div>
                <div class="card-body">
                  <div class="list-group">
                    <a
                      href="#"
                      class="list-group-item list-group-item-action active"
                    >
                      Cras justo odio
                    </a>
                    <a href="#" class="list-group-item list-group-item-action">
                      Dapibus ac facilisis in
                    </a>
                    <a href="#" class="list-group-item list-group-item-action">
                      Morbi leo risus
                    </a>
                    <a href="#" class="list-group-item list-group-item-action">
                      Porta ac consectetur ac
                    </a>
                    <a
                      href="#"
                      class="list-group-item list-group-item-action disabled"
                    >
                      Vestibulum at eros
                    </a>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h4>Contextual Classes</h4>
                </div>
                <div class="card-body">
                  <ul class="list-group">
                    <li class="list-group-item">Dapibus ac facilisis in</li>
                    <li class="list-group-item list-group-item-primary">
                      A simple primary list group item
                    </li>
                    <li class="list-group-item list-group-item-secondary">
                      A simple secondary list group item
                    </li>
                    <li class="list-group-item list-group-item-success">
                      A simple success list group item
                    </li>
                    <li class="list-group-item list-group-item-danger">
                      A simple danger list group item
                    </li>
                    <li class="list-group-item list-group-item-warning">
                      A simple warning list group item
                    </li>
                    <li class="list-group-item list-group-item-info">
                      A simple info list group item
                    </li>
                    <li class="list-group-item list-group-item-light">
                      A simple light list group item
                    </li>
                    <li class="list-group-item list-group-item-dark">
                      A simple dark list group item
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h4>Custom Content</h4>
                </div>
                <div class="card-body">
                  <div class="list-group">
                    <a
                      href="#"
                      class="list-group-item list-group-item-action flex-column align-items-start active"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">List group item heading</h5>
                        <small>3 days ago</small>
                      </div>
                      <p class="mb-1">
                        Donec id elit non mi porta gravida at eget metus.
                        Maecenas sed diam eget risus varius blandit.
                      </p>
                      <small>Donec id elit non mi porta.</small>
                    </a>
                    <a
                      href="#"
                      class="list-group-item list-group-item-action flex-column align-items-start"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">List group item heading</h5>
                        <small class="text-muted">3 days ago</small>
                      </div>
                      <p class="mb-1">
                        Donec id elit non mi porta gravida at eget metus.
                        Maecenas sed diam eget risus varius blandit.
                      </p>
                      <small class="text-muted">
                        Donec id elit non mi porta.
                      </small>
                    </a>
                    <a
                      href="#"
                      class="list-group-item list-group-item-action flex-column align-items-start"
                    >
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">List group item heading</h5>
                        <small class="text-muted">3 days ago</small>
                      </div>
                      <p class="mb-1">
                        Donec id elit non mi porta gravida at eget metus.
                        Maecenas sed diam eget risus varius blandit.
                      </p>
                      <small class="text-muted">
                        Donec id elit non mi porta.
                      </small>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
