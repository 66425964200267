import React, { useEffect, useState } from "react";
import axios from "axios";
import { FiEye, FiEyeOff } from "react-icons/fi";

const WNLawChangePassword = () => {
  const [userID, setUserID] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isValidNewPassword, setIsValidNewPassword] = useState(true);
  const [isValidConfPassword, setIsValidConfPassword] = useState(true);
  const [isError, setIsError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const API_URL = process.env.REACT_APP_API_BASE_URL;
  const [msg, setMsg] = useState("");

  const getProfileIdFromLocalStorage = async () => {
    try {
      const user = localStorage.getItem("user");

      if (user) {
        const userData = JSON.parse(user);
        console.log("userData is :", userData);
        setUserID(userData.user.id);
      }
    } catch (error) {
      console.log("Error fetching userID : ", error);
      setMsg(error);
      setIsError(true);
    }
  };

  const getUserLogin = async (setFirstName, setLastName, setEmail, setMsg) => {
    try {
      const response = await axios.get(`${API_URL}/getuserlogin/${userID}`);
      setFirstName(response.data.firstname);
      setLastName(response.data.lastname);
      setEmail(response.data.email);
      setMsg(response.data.msg);
    } catch (error) {
      console.log("Error fetching profile : ", error);
      setMsg(error);
      setIsError(true);
    }
  };
  const updatePassword = async (e) => {
    e.preventDefault();

    try {
      if (!password || !newPassword || !confPassword) {
        if (!password) {
          setIsValidPassword(false);
        }
        if (!newPassword) {
          setIsValidNewPassword(false);
        }
        if (!confPassword) {
          setIsValidConfPassword(false);
        }
        return;
      }
      console.log("Email", email);
      console.log("password", password);
      console.log("newPassword", newPassword);
      console.log("confPassword", confPassword);
      const response = await axios.post(`${API_URL}/changepassword`, {
        email: email,
        password: password,
        newPassword: newPassword,
        confPassword: confPassword,
      });
      console.log("Patch response:", response.data);
      setMsg(response.data.msg);
      setPassword("");
      setNewPassword("");
      setConfPassword("");
      setIsError(false);
      // navigate("/feature/wnlaw-profile", { replace: true });
      // window.location.reload();
    } catch (error) {
      console.log("Error while changing password:", error.response.data.msg);
      setMsg(error.response.data.msg);
      setIsError(true);
    }
  };

  const togglePasswordVisibility = (passwordType) => {
    switch (passwordType) {
      case "password":
        setShowPassword(!showPassword);
        break;
      case "newPassword":
        setShowNewPassword(!showNewPassword);
        break;
      case "confPassword":
        setShowConfPassword(!showConfPassword);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    console.log("msg:", msg);
  }, [msg]);

  useEffect(() => {
    console.log("isError:", isError);
  }, [isError]);

  useEffect(() => {
    getUserLogin(setFirstName, setLastName, setEmail, setMsg);
    getProfileIdFromLocalStorage();
  }, []);

  return (
    <div className="main-content">
      <section className="section">
        <div className="section-header">
          <h1>Change Password</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <a href="/dashboard/general">Dashboard</a>
            </div>
            <div className="breadcrumb-item">
              <a href="#">Auth</a>
            </div>
            <div className="breadcrumb-item">Change Password</div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">
            Hi, {firstName} {lastName} !
          </h2>
          <p className="section-lead">Change your password on this page.</p>

          <div className="row mt-sm-4">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="card">
                <form
                  // onSubmit={updatePassword}
                  method="post"
                  className="needs-validation"
                  noValidate=""
                >
                  <div className="card-header">
                    <h4>Change Password</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="form-group col-md-6 col-12">
                        Current Password&nbsp;
                        <span style={{ color: "red" }}>*</span>{" "}
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="fas fa-lock"></i>
                            </div>
                          </div>
                          <input
                            type={showPassword ? "text" : "password"}
                            className={`form-control pwstrength ${
                              isValidPassword ? "" : "is-invalid"
                            }`}
                            data-indicator="pwindicator"
                            value={password}
                            style={{ marginRight: "1px" }}
                            onChange={(event) => {
                              setPassword(event.target.value);
                              setIsValidPassword(true);
                            }}
                          />
                          <div className="input-group-append">
                            <button
                              className={`btn ${
                                showPassword
                                  ? "btn-outline-primary"
                                  : "btn-outline-secondary input-group-text"
                              }`}
                              type="button"
                              onClick={() =>
                                togglePasswordVisibility("password")
                              }
                            >
                              {showPassword ? <FiEyeOff /> : <FiEye />}
                            </button>
                          </div>
                          {!isValidPassword && (
                            <div className="invalid-feedback">
                              Please fill in the position
                            </div>
                          )}
                        </div>
                        {password && password.length < 6 && (
                          <div className="text-danger">
                            Password must be at least 6 characters long !
                          </div>
                        )}
                        <div id="pwindicator" className="pwindicator">
                          <div className="bar"></div>
                          <div className="label"></div>
                        </div>
                        <div className="invalid-feedback">
                          Please fill in the Confirm Password
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6 col-12">
                        New Password&nbsp;
                        <span style={{ color: "red" }}>*</span>{" "}
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="fas fa-lock"></i>
                            </div>
                          </div>
                          <input
                            type={showNewPassword ? "text" : "password"}
                            className={`form-control pwstrength ${
                              isValidNewPassword ? "" : "is-invalid"
                            }`}
                            data-indicator="pwindicator"
                            value={newPassword}
                            style={{ marginRight: "1px" }}
                            onChange={(event) => {
                              setNewPassword(event.target.value);
                              setIsValidNewPassword(true);
                            }}
                          />
                          <div className="input-group-append">
                            <button
                              className={`btn ${
                                showNewPassword
                                  ? "btn-outline-primary"
                                  : "btn-outline-secondary input-group-text"
                              }`}
                              type="button"
                              onClick={() =>
                                togglePasswordVisibility("newPassword")
                              }
                            >
                              {showNewPassword ? <FiEyeOff /> : <FiEye />}
                            </button>
                          </div>
                          {!isValidNewPassword && (
                            <div className="invalid-feedback">
                              Please fill in the position
                            </div>
                          )}
                        </div>
                        {newPassword && newPassword.length < 6 && (
                          <div className="text-danger">
                            Password must be at least 6 characters long !
                          </div>
                        )}
                        <div id="pwindicator" className="pwindicator">
                          <div className="bar"></div>
                          <div className="label"></div>
                        </div>
                        {newPassword !== confPassword && (
                          <div className="text-danger">
                            Passwords do not match
                          </div>
                        )}
                        <div className="invalid-feedback">
                          Please fill in the Confirm Password
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-12">
                        Confirm New Password&nbsp;
                        <span style={{ color: "red" }}>*</span>{" "}
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="fas fa-lock"></i>
                            </div>
                          </div>
                          <input
                            type={showConfPassword ? "text" : "password"}
                            className={`form-control pwstrength ${
                              isValidConfPassword ? "" : "is-invalid"
                            }`}
                            data-indicator="pwindicator"
                            value={confPassword}
                            style={{ marginRight: "1px" }}
                            onChange={(event) => {
                              setConfPassword(event.target.value);
                              setIsValidConfPassword(true);
                            }}
                          />
                          <div className="input-group-append">
                            <button
                              className={`btn ${
                                showConfPassword
                                  ? "btn-outline-primary"
                                  : "btn-outline-secondary input-group-text"
                              }`}
                              type="button"
                              onClick={() =>
                                togglePasswordVisibility("confPassword")
                              }
                            >
                              {showConfPassword ? <FiEyeOff /> : <FiEye />}
                            </button>
                          </div>
                          {!isValidConfPassword && (
                            <div className="invalid-feedback">
                              Please fill in the confirm password
                            </div>
                          )}
                        </div>
                        {confPassword && confPassword.length < 6 && (
                          <div className="text-danger">
                            Password must be at least 6 characters long !
                          </div>
                        )}
                        <div id="pwindicator" className="pwindicator">
                          <div className="bar"></div>
                          <div className="label"></div>
                        </div>
                        <div className="invalid-feedback">
                          Please fill in the Confirm Password
                        </div>
                      </div>
                    </div>
                    {isError ? (
                      <div className="text-left color text-danger">{msg}</div>
                    ) : (
                      <div className="text-left color text-success">{msg}</div>
                    )}
                  </div>
                  <div className="card-footer text-right">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={(e) => updatePassword(e)}
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WNLawChangePassword;
