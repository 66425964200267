import React, { Component } from "react";

export class Credit extends Component {
  render() {
    return (
      <div class="main-content">
        <section class="section">
          <div class="section-header">
            <h1>Credits</h1>
            <div class="section-header-breadcrumb">
              <div class="breadcrumb-item active">
                <a href="#">Dashboard</a>
              </div>
              <div class="breadcrumb-item">Credits</div>
            </div>
          </div>

          <div class="section-body">
            <h2 class="section-title">Thanks To ...</h2>
            <p class="section-lead">
              We would like to thank the makers of these cool plugins and
              images.
            </p>

            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-header">
                    <h4>Credits</h4>
                  </div>
                  <div class="card-body">
                    <div class="list-unstyled list-unstyled-border mt-4">
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Browser Icons</h6>
                          <p>by Marina D</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Bootstrap</h6>
                          <p>by @mdo and @fat</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Bootstrap Color Picker</h6>
                          <p>by Javi Aguilar</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Bootstrap Date Range</h6>
                          <p>by Dan Grossman</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Bootstrap Social Button</h6>
                          <p>by Panayiotis Lipiridis</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Bootstrap Tags Input</h6>
                          <p>by Schlechter</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Timepicker</h6>
                          <p>by @jdewit</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Chocolat</h6>
                          <p>by @nicolas-t</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Cleave.JS</h6>
                          <p>by @nosir</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Codemirror</h6>
                          <p>by Marijn Haverbeke</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>DataTables</h6>
                          <p>by @datatables</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Dropzone.JS</h6>
                          <p>by Matias Meno</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Flag Icon CSS</h6>
                          <p>by Panayiotis Lipiridis</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Font Awesome</h6>
                          <p>by @fontawesome</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Full Calendar</h6>
                          <p>by Adam Shaw</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>IonIcons</h6>
                          <p>by Ionic Framework</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>jQuery</h6>
                          <p>by The jQuery Foundation</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>jQuery PWStrength</h6>
                          <p>by Mato Ilic</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>jQuery Selectric</h6>
                          <p>by Leonardo Santos</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>jQuery UI</h6>
                          <p>by The jQuery Foundation</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>jQuery Vector Map</h6>
                          <p>by Manifest Interactive</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>NiceScroll</h6>
                          <p>by InuYaksa</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>OwlCarousel</h6>
                          <p>by David Deutsch</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Prism</h6>
                          <p>by @PrismJS</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Select2</h6>
                          <p>by Kevin Brown and Igor Vaynberg</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Simple Weather</h6>
                          <p>by James Fleeting</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Summernote</h6>
                          <p>by Alan Hong</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Sweet Alert</h6>
                          <p>by Tristan Edwards</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>iziToast</h6>
                          <p>by Dolce</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Upload Preview</h6>
                          <p>by Opoloo</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Weather Icon</h6>
                          <p>by Erik Flowers</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Chart.JS</h6>
                          <p>by Nick Downie</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>GMaps.JS</h6>
                          <p>by Gustavo Leon</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Sparkline</h6>
                          <p>by Gareth Watts</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Moment</h6>
                          <p>by @moment</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Popper.JS</h6>
                          <p>by Federico Zivolo</p>
                        </div>
                      </div>
                      <div class="media">
                        <div class="media-icon">
                          <i class="far fa-circle"></i>
                        </div>
                        <div class="media-body">
                          <h6>Tooltip.JS</h6>
                          <p>by Federico Zivolo</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Credit;
