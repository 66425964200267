import $ from "jquery"

export default function UserScroll() {


    $("#users-carousel").owlCarousel({
        items: 4,
        margin: 20,
        autoplay: true,
        autoplayTimeout: 5000,
        loop: true,
        responsive: {
            0: {
                items: 2,
            },
            578: {
                items: 4,
            },
            768: {
                items: 4,
            },
        },
    });


}