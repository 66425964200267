import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import SummerNoteJs from "../../../js/summernote";
import ToastrMessage from "../../../js/toastrMessage";
import axios from "axios";
import $ from "jquery";
import path from "path";

const WNLawEditPortofolio = () => {
  const [portofolioItems, setPortofolioItems] = useState(null);
  const [oldPhotoProfile, setOldPhotoProfile] = useState("");
  const [portofolioUrl, setPortofolioURL] = useState("");
  const [isValidPortofolioItems, setIsValidPortofolioItems] = useState(true);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const API_URL = process.env.REACT_APP_API_BASE_URL;
  const [msg, setMsg] = useState("");
  const [isError, setIsError] = useState(false);
  const imgPath = path.resolve(
    __dirname,
    "../../../../frontend/cms/public/assets/img"
  );
  const { id } = useParams();

  const navigate = useNavigate();

  const getPortofolioById = async () => {
    try {
      const response = await axios.get(`${API_URL}/portofolio/${id}`);
      console.log(response.data);
      setOldPhotoProfile(response.data.portofolio);

      if (response.data.portofolio) {
        const imageURL = `${API_URL}${imgPath}/${response.data.portofolio}`;
        const portofolioResponse = await axios.get(imageURL, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "image/jpeg",
          },
        });

        console.log("portofolioResponse:", portofolioResponse.config.url);
        setPortofolioURL(portofolioResponse.config.url);
        console.log("portofolioUrl:", imgPath);
      }
    } catch (error) {
      console.log("Error fetching Portofolio : ", error);
      setMsg(error.response.data.msg);
      setIsError(true);
    }
  };

  const updatePortofolio = async (e) => {
    e.preventDefault();

    console.log("oldPhotoProfile:", oldPhotoProfile);
    console.log("portofolioItems:", portofolioItems.name);

    if (portofolioItems) {
      if (portofolioItems.size > 10 * 1024 * 1024) {
        setMsg("Limit size of the photo cannot more than 10 MB !");
        setIsError(true);
        return;
      }
      if (portofolioItems.type) {
        if (
          !["image/png", "image/jpeg", "image/jpg"].includes(
            portofolioItems.type
          )
        ) {
          console.log("Unsupported file type");
          return;
        }
        handleFileChange(portofolioItems);
      }
    }

    if (oldPhotoProfile && portofolioItems.name !== oldPhotoProfile) {
      try {
        await axios.delete(`${API_URL}/photoPortofolio/${id}`);
        console.log("Old photo profile deleted successfully");
      } catch (error) {
        console.log("Error deleting old photo profile:", error);
        setMsg(error.response.data.msg);
        setIsError(true);
      }
    }

    try {
      if (!portofolioItems) {
        if (!isValidPortofolioItems) {
          setIsValidPortofolioItems(false);
        }
        return;
      }

      console.log(`Updating Users with ID ${id}...`);

      const formData = new FormData();

      formData.append("photoProfile", portofolioItems);

      const response = await axios.patch(
        `${API_URL}/portofolio/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Patch response:", response.data);
      setMsg(response.data.msg);
      setIsError(false);
      setTimeout(() => {
        window.location.href = "/feature/wnlaw-portofolio";
      }, 1000);
    } catch (error) {
      console.log("Error updating services:", error);
      setMsg(error.response.data.msg);
      setIsError(true);
    }
  };

  const handleFileChange = (event) => {
    if (event && event.target && event.target.files) {
      console.log("Event is valid");
      const selectedFile = event.target.files[0];

      if (selectedFile) {
        setPortofolioItems(selectedFile);
        const url = URL.createObjectURL(selectedFile);
        if (url) {
          setPortofolioURL(url);
        }

        setIsFileUploaded(true);
        console.log("selectedFile:", selectedFile);

        const isValidFormat = ["image/png", "image/jpeg", "image/jpg"].includes(
          selectedFile.type
        );

        const imagePreviewDiv = document.getElementById("image-preview");

        if (isValidFormat) {
          imagePreviewDiv.classList.remove("is-invalid");
        } else {
          imagePreviewDiv.classList.add("is-invalid");
        }

        imagePreviewDiv.removeAttribute("style");
      } else {
        console.log("No file selected");
      }
    } else {
      console.log("Invalid event or no file selected");
      // Handle other cases where the event or file selection is invalid
    }
  };

  useEffect(() => {
    ToastrMessage();
  }, []);

  useEffect(() => {
    getPortofolioById();

    $("select").selectric();
    $.uploadPreview({
      input_field: "#image-upload",
      preview_box: "#image-preview",
      label_field: "#image-label",
      label_default: "Choose File",
      label_selected: "Change File",
      no_label: false,
      success_callback: null,
    });
    $(".inputtags").tagsinput("items");

    SummerNoteJs();
  }, []);

  return (
    <div className="main-content">
      <section className="section">
        <div className="section-header">
          <div className="section-header-back">
            <Link to="/feature/wnlaw-portofolio" className="btn btn-icon">
              <i className="fas fa-arrow-left"></i>
            </Link>
          </div>
          <h1>Edit Portofolio</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to="/dashboard/general">Dashboard</Link>
            </div>
            <div className="breadcrumb-item">
              <a href="#">Document</a>
            </div>
            <div className="breadcrumb-item active">
              <Link to="/feature/wnlaw-portofolio">Portofolio</Link>
            </div>
            <div className="breadcrumb-item">Edit Portofolio</div>
          </div>
        </div>
        <div className="section-body">
          <p className="section-title">Insert your photo portofolio here</p>

          <div className="row mt-sm-4">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="card">
                <form method="post" className="needs-validation" noValidate="">
                  <div className="card-header">
                    <h4>Edit Portofolio</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="form-group col-md-6 col-12">
                        <label>Portofolio Photo</label>
                        <div id="image-preview" className="image-preview">
                          <input
                            type="file"
                            name="image"
                            id="image-upload"
                            onChange={(event) => {
                              handleFileChange(event);
                              setPortofolioURL(
                                `${imgPath}/${
                                  event ? null : event.target.files[0].name
                                }`
                              );
                            }}
                            style={{
                              display: isFileUploaded ? "none" : "block",
                            }}
                          />
                          <label htmlFor="image-upload" id="image-label">
                            Choose File
                          </label>
                          {portofolioUrl && (
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                height: "100px",
                              }}
                            >
                              {isFileUploaded ? (
                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: "-130px",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                    onClick={() => {
                                      const imagePreviewDiv = document.getElementById(
                                        "image-preview"
                                      );
                                      imagePreviewDiv.style.backgroundImage =
                                        "none";
                                      setPortofolioItems(null);
                                      setPortofolioURL("");
                                      setIsFileUploaded(false);
                                    }}
                                  >
                                    <i className="fas fa-trash"></i>
                                  </button>
                                </div>
                              ) : (
                                <img
                                  src={portofolioUrl}
                                  alt="Photo Profile"
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "300px",
                                  }}
                                />
                              )}
                            </div>
                          )}
                        </div>
                        <div className="text">
                          Limit size of the photo is 10 MB.
                        </div>
                        {portofolioItems &&
                          !["image/png", "image/jpeg", "image/jpg"].includes(
                            portofolioItems.type
                          ) && (
                            <div className="invalid-feedback">
                              Please select a PNG, JPG, or JPEG file.
                            </div>
                          )}

                        {portofolioItems &&
                          portofolioItems.size > 10 * 1024 * 1024 && (
                            <div className="invalid-feedback">
                              File size should not exceed 10MB.
                            </div>
                          )}
                      </div>
                    </div>
                    {isError ? (
                      <div className="text-left color text-danger">{msg}</div>
                    ) : (
                      <div className="text-left color text-success">{msg}</div>
                    )}
                  </div>
                  <div className="card-footer text-right">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={(e) => updatePortofolio(e)}
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WNLawEditPortofolio;
