import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SummerNoteJs from "../../../js/summernote";
import ToastrMessage from "../../../js/toastrMessage";
import axios from "axios";
import $ from "jquery";
import path, { dirname } from "path";

const WnLawProfile = () => {
  const [userID, setUserID] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [photoProfile, setPhotoProfile] = useState("");
  const [oldPhotoProfile, setOldPhotoProfile] = useState("");
  const [photoProfileURL, setPhotoProfileURL] = useState("");
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [validToastrId, setValidToastrId] = useState(true);
  const [msg, setMsg] = useState("");
  const [isError, setIsError] = useState(false);
  const API_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const imgPath = path.resolve(
    __dirname,
    "../../../../frontend/cms/public/assets/img"
  );
  const getProfileIdFromLocalStorage = async () => {
    try {
      const user = localStorage.getItem("user");

      if (user) {
        const userData = JSON.parse(user);
        // console.log("userData is :", userData);
        setUserID(userData.user.id);
      }
    } catch (error) {
      // console.log("Error fetching userID : ", error);
      setMsg(error.response.data.msg);
      setIsError(true);
    }
  };

  const getProfileById = async (
    setFirstName,
    setLastName,
    setEmail,
    setPhone,
    setOldPhotoProfile
  ) => {
    try {
      const response = await axios.get(`${API_URL}/profile/${userID}`);
      setFirstName(response.data.firstname);
      setLastName(response.data.lastname);
      setEmail(response.data.email);
      setPhone(response.data.phone);
      setOldPhotoProfile(response.data.photoProfile);
      // console.log("User Profile is :", response);

      if (response.data.photoProfile) {
        const imageURL = `${API_URL}${imgPath}/${response.data.photoProfile}`;
        const photoProfileResponse = await axios.get(imageURL, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "image/jpeg",
          },
        });

        setPhotoProfileURL(photoProfileResponse.config.url);
        // console.log("photoProfileURL:", imgPath);
      }
    } catch (error) {
      // console.log("Error fetching profile : ", error);
      setMsg(error.response.data.msg);
      setIsError(true);
    }
  };

  const updateProfile = async (e) => {
    e.preventDefault();

    // console.log("oldPhotoProfile:", oldPhotoProfile.name);
    // console.log("photoProfile:", photoProfile.name);

    if (photoProfile) {
      // console.log("photoProfile : ", photoProfile);
      if (photoProfile.size > 1 * 1024 * 1024) {
        console.log("file.size : ", photoProfile.size);
        setMsg("Limit size of the photo cannot more than 10 MB !");
        setIsError(true);
        return;
      }

      if (photoProfile.type) {
        if (
          !["image/png", "image/jpeg", "image/jpg"].includes(photoProfile.type)
        ) {
          // console.log("Unsupported file type");
          return;
        }
        handleFileChange(photoProfile);
      }
    }

    if (oldPhotoProfile && photoProfile !== oldPhotoProfile) {
      try {
        await axios.delete(`${API_URL}/userPhotoProfile/${userID}`);
        // console.log("Old photo profile deleted successfully");
      } catch (error) {
        // console.log("Error deleting old photo profile:", error);
        setMsg(error.response.data.msg);
        setIsError(true);
      }
    }

    try {
      if (!firstName || !lastName || !email) {
        if (!firstName) {
          setIsValidFirstName(false);
          setValidToastrId(false);
        }
        if (!lastName) {
          setIsValidLastName(false);
          setValidToastrId(false);
        }

        if (!email) {
          setIsValidEmail(false);
          setValidToastrId(false);
        }
        return;
      }

      // console.log(`Updating profile with ID ${userID}...`);

      const formData = new FormData();

      formData.append("firstname", firstName);
      formData.append("lastname", lastName);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("photoProfile", photoProfile);
      // console.log("photoProfile : ", photoProfile);
      const response = await axios.patch(
        `${API_URL}/profile/${userID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log("Patch response:", response.data);
      setValidToastrId(true);
      // console.log("validToastrId after update:", validToastrId);

      setMsg(response.data.msg);
      setIsError(false);
      setTimeout(() => {
        window.location.href = "/feature/wnlaw-profile";
        // navigate("/feature/wnlaw-profile", { replace: true });
      }, 1000);
      // window.location.reload();
    } catch (error) {
      // console.log("Error updating profile:", error);
      setMsg(error.response.data.msg);
      setIsError(true);
      // setValidToastrId(false);
    }
  };

  const handleFileChange = (event) => {
    if (event && event.target && event.target.files) {
      // console.log("Event is valid");
      const selectedFile = event.target.files[0];

      if (selectedFile) {
        setPhotoProfile(selectedFile);
        const url = URL.createObjectURL(selectedFile);
        if (url) {
          setPhotoProfileURL(url);
        }

        setIsFileUploaded(true);
        // console.log("selectedFile:", selectedFile);

        const isValidFormat = ["image/png", "image/jpeg", "image/jpg"].includes(
          selectedFile.type
        );

        const imagePreviewDiv = document.getElementById("image-preview");

        if (isValidFormat) {
          imagePreviewDiv.classList.remove("is-invalid");
        } else {
          imagePreviewDiv.classList.add("is-invalid");
        }

        imagePreviewDiv.removeAttribute("style");
      } else {
        console.log("No file selected");
      }
    } else {
      console.log("Invalid event or no file selected");
      // Handle other cases where the event or file selection is invalid
    }
  };

  // useEffect(() => {
  //   console.log("validToastrId:", validToastrId);
  // }, [validToastrId]);

  useEffect(() => {
    // ToastrMessage();
    getProfileIdFromLocalStorage();
  }, []);

  useEffect(() => {
    getProfileById(
      setFirstName,
      setLastName,
      setEmail,
      setPhone,
      setOldPhotoProfile
    );

    $("select").selectric();
    $.uploadPreview({
      input_field: "#image-upload",
      preview_box: "#image-preview",
      label_field: "#image-label",
      label_default: "Choose File",
      label_selected: "Change File",
      no_label: false,
      success_callback: null,
    });
    $(".inputtags").tagsinput("items");

    SummerNoteJs();
  }, [userID]);

  useEffect(() => {
    // ToastrMessage();
    getProfileIdFromLocalStorage();
  }, []);
  return (
    <div className="main-content">
      <section className="section">
        {/* {console.log("validToastrId:", validToastrId)} */}
        <div className="section-header">
          <h1>Profile</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <a href="/dashboard/general">Dashboard</a>
            </div>
            <div className="breadcrumb-item">
              <a href="#">Document</a>
            </div>
            <div className="breadcrumb-item">Profile</div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">
            Hi, {firstName} {lastName} !
          </h2>
          <p className="section-lead">
            Change information about yourself on this page.
          </p>

          <div className="row mt-sm-4">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="card">
                <form
                  // onSubmit={updateProfile}
                  method="post"
                  className="needs-validation"
                  noValidate=""
                >
                  <div className="card-header">
                    <h4>Edit Profile</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="form-group col-md-6 col-12">
                        First Name&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <input
                          type="text"
                          className={`form-control ${
                            isValidFirstName ? "" : "is-invalid"
                          }`}
                          onChange={(event) => {
                            setFirstName(event.target.value);
                            setIsValidFirstName(true);
                            setValidToastrId(true);
                          }}
                          value={firstName}
                          required
                        />
                        {!isValidFirstName && (
                          <div className="invalid-feedback">
                            Please fill in the first name
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-6 col-12">
                        Last Name&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <input
                          type="text"
                          className={`form-control ${
                            isValidLastName ? "" : "is-invalid"
                          }`}
                          onChange={(event) => {
                            setLastName(event.target.value);
                            setIsValidLastName(true);
                            setValidToastrId(true);
                          }}
                          value={lastName}
                          required
                        />
                        {!isValidLastName && (
                          <div className="invalid-feedback">
                            Please fill in the last name
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6 col-12">
                        Email&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <input
                          type="email"
                          className={`form-control ${
                            isValidEmail ? "" : "is-invalid"
                          }`}
                          placeholder="example@email.com"
                          onChange={(event) => {
                            setEmail(event.target.value);
                            setIsValidEmail(true);
                            setValidToastrId(true);
                          }}
                          value={email}
                          required
                        />
                        {!isValidEmail && (
                          <div className="invalid-feedback">
                            Please fill in the email
                          </div>
                        )}
                      </div>
                      {/* Phone field goes here */}
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6 col-12">
                        <label>Photo Profile</label>
                        <div id="image-preview" className="image-preview">
                          <input
                            type="file"
                            name="image"
                            id="image-upload"
                            onChange={(event) => {
                              // setOldPhotoProfile(
                              //   event && event.target.files.length > 0
                              //     ? event.target.files[0]
                              //     : null
                              // );
                              handleFileChange(event);
                              // console.log(
                              //   "photoProfile:",
                              //   event.target.files[0]
                              // );
                              setPhotoProfileURL(
                                `${imgPath}/${
                                  event ? null : event.target.files[0].name
                                }`
                              );
                            }}
                            style={{
                              display: isFileUploaded ? "none" : "block",
                            }}
                          />
                          <label htmlFor="image-upload" id="image-label">
                            Choose File
                          </label>
                          {photoProfileURL && !isFileUploaded && (
                            <img
                              src={photoProfileURL}
                              alt="Photo Profile"
                              style={{ maxWidth: "100%", maxHeight: "300px" }}
                            />
                          )}
                        </div>
                        <div className="text">
                          Limit size of the photo is 10 MB.
                        </div>
                        {photoProfile &&
                          !["image/png", "image/jpeg", "image/jpg"].includes(
                            photoProfile.type
                          ) && (
                            <div className="invalid-feedback">
                              Please select a PNG, JPG, or JPEG file.
                            </div>
                          )}

                        {photoProfile &&
                          photoProfile.size > 1 * 1024 * 1024 && (
                            <div className="invalid-feedback">
                              File size should not exceed 10MB.
                            </div>
                          )}
                      </div>
                    </div>
                    {isError ? (
                      <div className="text-left color text-danger">{msg}</div>
                    ) : (
                      <div className="text-left color text-success">{msg}</div>
                    )}
                  </div>
                  <div className="card-footer text-right">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={(e) => updateProfile(e)} // Memastikan bahwa Anda meneruskan objek event (e)
                      // id={validToastrId ? "toastr-9" : "toastr-11"}
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WnLawProfile;
