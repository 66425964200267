import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import SummerNoteJs from "../../js/summernote";
import ToastrMessage from "../../js/toastrMessage";
import { BootstrapModaljs } from "../../js/BootstrapModal";
import Select from "react-select";
import axios from "axios";
import $ from "jquery";
import path, { dirname } from "path";

const WNLawEditTeams = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [desc, setDesc] = useState("");
  const [office, setOffice] = useState("");
  const [phone, setPhone] = useState("");
  const [position, setPosition] = useState("");
  const [role, setRole] = useState("");
  const [photoProfile, setPhotoProfile] = useState("");
  const [oldPhotoProfile, setOldPhotoProfile] = useState("");
  const [photoProfileURL, setPhotoProfileURL] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isValidDesc, setIsValidDesc] = useState(true);
  const [isValidOffice, setIsValidOffice] = useState(true);
  const [isValidPosition, setIsValidPosition] = useState(true);
  const [isValidRole, setIsValidRole] = useState(true);
  const [validToastrId, setValidToastrId] = useState(true);
  const [msg, setMsg] = useState("");
  const [isError, setIsError] = useState(false);
  const API_URL = process.env.REACT_APP_API_BASE_URL;
  // const imgPath = path.resolve(__dirname, "../../../../backend/img");
  const imgPath = path.resolve(
    __dirname,
    "../../../../frontend/cms/public/assets/img"
  );
  const { id } = useParams();
  const navigate = useNavigate();

  const options = [
    { value: "Admin", label: "Admin" },
    { value: "Staff", label: "Staff" },
  ];

  const getTeamsById = async (
    setFirstName,
    setLastName,
    setEmail,
    setDesc,
    setOffice,
    setPhone,
    setPosition,
    setRole,
    setOldPhotoProfile
  ) => {
    try {
      const response = await axios.get(`${API_URL}/users/${id}`);

      console.log(response.data);
      setFirstName(response.data.firstname);
      setLastName(response.data.lastname);
      setEmail(response.data.email);
      setDesc(response.data.desc);
      setOffice(response.data.office);
      setPhone(response.data.phone);
      setPosition(response.data.position);
      setRole(response.data.role);
      setOldPhotoProfile(response.data.photoProfile);
      // $('.summernote-simple').summernote('pasteHTML', response.data.content);
      if (response.data.photoProfile) {
        const imageURL = `${API_URL}${imgPath}/${response.data.photoProfile}`;
        const photoProfileResponse = await axios.get(imageURL, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "image/jpeg",
          },
        });

        setPhotoProfileURL(photoProfileResponse.config.url);
        // console.log("photoProfileURL:", imgPath);
      }
    } catch (error) {
      // console.log("Error fetching Users : ", error);
      setMsg(error.response.data.msg);
      setIsError(true);
    }
  };

  const updateUsers = async (e) => {
    e.preventDefault();

    if (photoProfile) {
      if (photoProfile.size > 1 * 1024 * 1024) {
        // console.log("file.size : ", photoProfile.size);
        return;
      }
      if (photoProfile.type) {
        if (
          !["image/png", "image/jpeg", "image/jpg"].includes(photoProfile.type)
        ) {
          // console.log("Unsupported file type");
          return;
        }
        handleFileChange(photoProfile);
      }
    }

    if (oldPhotoProfile && photoProfile !== oldPhotoProfile) {
      try {
        await axios.delete(`${API_URL}/userPhotoProfile/${id}`);
        // console.log("Old photo profile deleted successfully");
      } catch (error) {
        console.log("Error deleting old photo profile:", error);
        setMsg(error.response.data.msg);
        setIsError(true);
      }
    }

    try {
      if (
        !firstName ||
        !lastName ||
        !email ||
        !role ||
        !desc ||
        !office ||
        !phone ||
        !position ||
        !role
      ) {
        if (!firstName) {
          setIsValidFirstName(false);
          setValidToastrId(false);
        }
        if (!lastName) {
          setIsValidLastName(false);
          setValidToastrId(false);
        }

        if (!email) {
          setIsValidEmail(false);
          setValidToastrId(false);
        }
        if (!phone) {
          setIsValidPhone(false);
          setValidToastrId(false);
        }
        if (!desc) {
          setIsValidDesc(false);
          setValidToastrId(false);
        }
        if (!office) {
          setIsValidOffice(false);
          setValidToastrId(false);
        }
        if (!position) {
          setIsValidPosition(false);
          setValidToastrId(false);
        }
        if (!role) {
          setIsValidRole(false);
          setValidToastrId(false);
        }

        return;
      }

      // console.log(`Updating Users with ID ${id}...`);

      const formData = new FormData();
      formData.append("firstname", firstName);
      formData.append("lastname", lastName);
      formData.append("email", email);
      formData.append("desc", desc);
      formData.append("office", office);
      formData.append("phone", phone);
      formData.append("position", position);
      formData.append("role", role);
      formData.append("photoProfile", photoProfile);

      const response = await axios.patch(`${API_URL}/users/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // console.log("photoProfile:", photoProfile);
      // console.log("Patch response:", response);
      // navigate("/form/teams", { replace: true });
      setMsg(response.data.msg);
      setIsError(false);
      setTimeout(() => {
        window.location.href = "/form/teams";
      }, 1000);
      setValidToastrId(true);
    } catch (error) {
      // console.log("Error updating services:", error);
      setValidToastrId(false);
      setMsg(error.response.data.msg);
      setIsError(true);
    }
  };

  // useEffect(() => {
  //   console.log("validToastrId:", validToastrId);
  // }, [validToastrId]);

  useEffect(() => {
    // BootstrapModaljs();
    // ToastrMessage();
    getTeamsById(
      setFirstName,
      setLastName,
      setEmail,
      setDesc,
      setOffice,
      setPhone,
      setPosition,
      setRole,
      setOldPhotoProfile
    );

    $("select").selectric();
    $.uploadPreview({
      input_field: "#image-upload",
      preview_box: "#image-preview",
      label_field: "#image-label",
      label_default: "Choose File",
      label_selected: "Change File",
      no_label: false,
      success_callback: null,
    });
    $(".inputtags").tagsinput("items");

    SummerNoteJs();

    // return () => {
    //   if (photoProfileURL) {
    //     URL.revokeObjectURL(photoProfileURL);
    //   }
    // };
  }, []);

  const handleFileChange = (event) => {
    if (event && event.target && event.target.files) {
      // console.log("Event is valid");
      const selectedFile = event.target.files[0];

      if (selectedFile) {
        setPhotoProfile(selectedFile);
        const url = URL.createObjectURL(selectedFile);
        if (url) {
          setPhotoProfileURL(url);
        }

        setIsFileUploaded(true);
        // console.log("selectedFile:", selectedFile);

        const isValidFormat = ["image/png", "image/jpeg", "image/jpg"].includes(
          selectedFile.type
        );

        const imagePreviewDiv = document.getElementById("image-preview");

        if (isValidFormat) {
          imagePreviewDiv.classList.remove("is-invalid");
        } else {
          imagePreviewDiv.classList.add("is-invalid");
        }

        imagePreviewDiv.removeAttribute("style");
      } else {
        console.log("No file selected");
      }
    } else {
      console.log("Invalid event or no file selected");
    }
  };

  return (
    <div className="main-content">
      <section className="section">
        <div className="section-header">
          <div class="section-header-back">
            <Link to="/form/teams" class="btn btn-icon">
              <i class="fas fa-arrow-left"></i>
            </Link>
          </div>
          <h1>Edit Teams</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to="/dashboard/general">Dashboard</Link>
            </div>
            <div className="breadcrumb-item">
              <Link to="#">Document</Link>
            </div>
            <div className="breadcrumb-item">
              <Link to="/form/teams">Teams</Link>
            </div>
            <div className="breadcrumb-item">Edit Teams</div>
          </div>
        </div>
        <div className="section-body">
          <h1 className="section-title">
            Change the information about your team member on this page.
          </h1>

          <div className="row mt-sm-4">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="card">
                <form method="post" className="needs-validation" noValidate="">
                  <div className="card-header">
                    <h4>Edit Teams</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="form-group col-md-6 col-12">
                        First Name&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="ion-person"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            className={`form-control ${
                              !isValidFirstName ? "is-invalid" : ""
                            }`}
                            onChange={(event) => {
                              setFirstName(event.target.value);
                              setIsValidFirstName(true);
                            }}
                            value={firstName}
                            required
                          />
                          {!isValidFirstName && (
                            <div className="invalid-feedback">
                              Please fill in the first name
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-12">
                        Last Name&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="ion-person"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            className={`form-control ${
                              isValidLastName ? "" : "is-invalid"
                            }`}
                            onChange={(event) => {
                              setLastName(event.target.value);
                              setIsValidLastName(true);
                              // setValidToastrId(true);
                            }}
                            value={lastName}
                            required
                          />
                          {!isValidLastName && (
                            <div className="invalid-feedback">
                              Please fill in the last name
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6 col-12">
                        Email&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="fas fa-envelope"></i>
                            </div>
                          </div>
                          <input
                            type="email"
                            className={`form-control ${
                              isValidEmail ? "" : "is-invalid"
                            }`}
                            placeholder="example@email.com"
                            required=""
                            value={email}
                            onChange={(event) => {
                              setEmail(event.target.value);
                              setIsValidEmail(true);
                              // setValidToastrId(true);
                            }}
                          />
                          {!isValidEmail && (
                            <div className="invalid-feedback">
                              Please fill in the email
                            </div>
                          )}
                        </div>
                        {email && !email.includes("@") && (
                          <div className="text-danger">
                            Invalid email format !
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-6 col-12">
                        Phone&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="fas fa-phone"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            className={`form-control phone-number ${
                              isValidPhone ? "" : "is-invalid"
                            }`}
                            value={phone}
                            required
                            onChange={(event) => {
                              setPhone(event.target.value);
                              setIsValidPhone(true);
                              // setValidToastrId(true);
                            }}
                          />
                          {!isValidPhone && (
                            <div className="invalid-feedback">
                              Please fill in the phone
                            </div>
                          )}
                        </div>
                        {phone && phone.length < 9 && (
                          <div className="text-danger">
                            Phone number must be at least 9 characters long !
                          </div>
                        )}
                        {phone && !/^\d+$/.test(phone) && (
                          <div className="text-danger">
                            Phone number must contain only numbers.
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6 col-12">
                        Office&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="ion-android-home"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            className={`form-control ${
                              isValidOffice ? "" : "is-invalid"
                            }`}
                            data-indicator="pwindicator"
                            value={office}
                            required
                            onChange={(event) => {
                              setOffice(event.target.value);
                              setIsValidOffice(true);
                              // setValidToastrId(true);
                            }}
                          />
                          {!isValidOffice && (
                            <div className="invalid-feedback">
                              Please fill in the office
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-12">
                        Position&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="ion-briefcase"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            className={`form-control ${
                              isValidPosition ? "" : "is-invalid"
                            }`}
                            data-indicator="pwindicator"
                            value={position}
                            required
                            onChange={(event) => {
                              setPosition(event.target.value);
                              setIsValidPosition(true);
                              // setValidToastrId(true);
                            }}
                          />
                          {!isValidPosition && (
                            <div className="invalid-feedback">
                              Please fill in the position
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6 col-12">
                        Role&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <Select
                          options={options}
                          value={options.find(
                            (option) => option.value === role
                          )}
                          required
                          onChange={(selectedOption) => {
                            setRole(selectedOption.value);
                            setIsValidRole(true);
                            // setValidToastrId(true);
                          }}
                        />
                        {!isValidRole && (
                          <div className="invalid-feedback">
                            Please fill in the role
                          </div>
                        )}
                      </div>

                      <div className="form-group col-md-12 col-12">
                        Description&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <textarea
                          className={`form-control ${
                            isValidDesc ? "" : "is-invalid"
                          }`}
                          style={{ minHeight: "300px", resize: "none" }}
                          value={desc}
                          onChange={(event) => {
                            setDesc(event.target.value);
                            setIsValidDesc(true);
                            // setValidToastrId(true);
                          }}
                          rows={10}
                        ></textarea>
                        {!isValidDesc && (
                          <div className="invalid-feedback">
                            Please fill in the description
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6 col-12">
                        <label>Photo Profile</label>
                        <div id="image-preview" className="image-preview">
                          <input
                            type="file"
                            name="image"
                            id="image-upload"
                            onChange={(event) => {
                              setOldPhotoProfile(
                                event && event.target.files.length > 0
                                  ? event.target.files[0]
                                  : null
                              );

                              handleFileChange(event);
                              // console.log(
                              //   "photoProfile:",
                              //   event ? null : event.target.files[0]
                              // );
                              setPhotoProfileURL(
                                `${imgPath}/${
                                  event ? null : event.target.files[0].name
                                }`
                              );
                            }}
                            style={{
                              display: isFileUploaded ? "none" : "block",
                            }}
                          />
                          <label htmlFor="image-upload" id="image-label">
                            Choose File
                          </label>
                          {photoProfileURL && (
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                height: "100px",
                              }}
                            >
                              {isFileUploaded ? (
                                <div
                                  style={{
                                    position: "absolute",
                                    bottom: "-130px",
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                    onClick={() => {
                                      const imagePreviewDiv = document.getElementById(
                                        "image-preview"
                                      );
                                      imagePreviewDiv.style.backgroundImage =
                                        "none";
                                      setPhotoProfile(null);
                                      setPhotoProfileURL("");
                                      setIsFileUploaded(false);
                                    }}
                                  >
                                    <i className="fas fa-trash"></i>
                                  </button>
                                </div>
                              ) : (
                                <img
                                  src={photoProfileURL}
                                  alt="Photo Profile"
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "300px",
                                  }}
                                />
                              )}
                            </div>
                          )}
                        </div>
                        <div className="text">
                          Limit size of the photo is 10 MB.
                        </div>
                        {photoProfile &&
                          !["image/png", "image/jpeg", "image/jpg"].includes(
                            photoProfile.type
                          ) && (
                            <div className="invalid-feedback">
                              Please select a PNG, JPG, or JPEG file.
                            </div>
                          )}

                        {photoProfile &&
                          photoProfile.size > 1 * 1024 * 1024 && (
                            <div className="invalid-feedback">
                              File size should not exceed 10MB.
                            </div>
                          )}
                      </div>
                    </div>
                    {isError ? (
                      <div className="text-left color text-danger">{msg}</div>
                    ) : (
                      <div className="text-left color text-success">{msg}</div>
                    )}
                  </div>
                  <div className="card-footer text-right">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="toastr-9"
                      data-toggle="modal"
                      onClick={(e) => updateUsers(e)}
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WNLawEditTeams;
