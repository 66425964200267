import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { loginUser } from "../Features/auth-slice";
import image from "../../../assets/img/logo_wnlaw_big.jpg";
import loginImage from "../../../assets/img/login-image.jpg";
import { FiEye, FiEyeOff } from "react-icons/fi";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const auth = () => {
      try {
        const isAuthenticated = localStorage.getItem("user");
        console.log("Is Auth : ", isAuthenticated);
        if (isAuthenticated === null) {
          return false;
        }
        console.log(
          "Auth JSON Parse : ",
          JSON.parse(isAuthenticated).isSuccess
        );
        return JSON.parse(isAuthenticated).isSuccess;
      } catch (e) {
        console.log(e);
        return false;
      }
    };

    if (auth()) {
      navigate("/dashboard/general", { replace: true });
    }
  }, []);

  const Auth = (event) => {
    event.preventDefault();

    if (!email || !password) {
      if (!email) {
        setIsValidEmail(false);
      }
      if (!password) {
        setIsValidPassword(false);
      }

      return;
    }
    dispatch(loginUser({ email, password }))
      .then((response) => {
        setMsg(response.payload);
        console.log("Response di Login : ", response);
        console.log(
          "Response di Login Error / Tidak : ",
          response.meta.requestStatus
        );
        if (response.meta.requestStatus != "rejected") {
          // localStorage.setItem("user", JSON.stringify(response.payload));
          setTimeout(() => {
            navigate("/dashboard/general", { replace: true });
            console.log("Login Success : ", response.payload);
          }, 1000);
          setTimeout(() => {
            window.location.reload();
          }, 0);
        } else {
          console.error("Login failed:", "No user data found in response");
        }
      })
      .catch((error) => {
        console.error("Login failed:", error.message);
        alert(error.message);
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Mendapatkan message dari local storage
  // const getMessageFromLocalStorage = () => {
  //   const userData = JSON.parse(localStorage.getItem("user"));
  //   return userData ? userData.message : "";
  // };

  // if (userAuthed) return <Navigate to="/dahsboard/general" />;
  return (
    <div
      id="app"
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <section className="section">
        <div className="container mt-5">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-10 offset-lg-1 col-xl-10 offset-xl-1">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-5">
                      <form
                        // onSubmit={Auth}
                        method="POST"
                        action="#"
                        noValidate
                        className="needs-validation"
                      >
                        <div className="control-label">
                          <img
                            src={image}
                            alt="image"
                            className="img-fluid "
                            style={{ maxWidth: "100px", height: "auto" }}
                          />
                          <p
                            className="mb-4 mt-4"
                            style={{ fontSize: "1.1em" }}
                          >
                            <b>WIDYANUSA, WIDDHI & CO </b> is a partnership of
                            experienced lawyers as well as legal counsels in a
                            law firm that provides personal, partner-level
                            attention to all clients.
                          </p>
                          <label
                            for="email"
                            className="control-label"
                            style={{ fontSize: "1em" }}
                          >
                            Email
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i className="fas fa-envelope"></i>
                              </div>
                            </div>
                            <input
                              id="email"
                              type="email"
                              className={`form-control ${
                                isValidEmail ? "" : "is-invalid"
                              }`}
                              name="email"
                              value={email}
                              onChange={(event) => {
                                setEmail(event.target.value);
                                setIsValidEmail(true);
                              }}
                              tabIndex="1"
                              required
                              autoFocus
                            />
                            {!isValidEmail && (
                              <div className="invalid-feedback">
                                Please fill in the email
                              </div>
                            )}
                          </div>
                        </div>
                        <div style={{ margin: "20px" }}></div>
                        <div className="control-label">
                          <label
                            for="password"
                            className="control-label"
                            style={{ fontSize: "1em" }}
                          >
                            Password
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i className="fas fa-lock"></i>
                              </div>
                            </div>
                            <input
                              type={showPassword ? "text" : "password"}
                              className={`form-control pwstrength ${
                                isValidPassword ? "" : "is-invalid"
                              }`}
                              data-indicator="pwindicator"
                              value={password}
                              style={{ marginRight: "1px" }}
                              onChange={(e) => {
                                setPassword(e.target.value);
                                setIsValidPassword(true);
                              }}
                            />
                            <div className="input-group-append">
                              <button
                                className={`btn ${
                                  showPassword
                                    ? "btn-outline-primary"
                                    : "btn-outline-secondary input-group-text"
                                }`}
                                type="button"
                                onClick={() =>
                                  togglePasswordVisibility("password")
                                }
                              >
                                {showPassword ? <FiEyeOff /> : <FiEye />}
                              </button>
                            </div>
                            {!isValidPassword && (
                              <div className="invalid-feedback">
                                Please fill in the position
                              </div>
                            )}
                          </div>
                          <div id="pwindicator" className="pwindicator">
                            <div className="bar"></div>
                            <div className="label"></div>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              name="remember"
                              className="custom-control-input"
                              tabIndex="3"
                              id="remember-me"
                            />
                            <label
                              className="custom-control-label"
                              for="remember-me"
                            >
                              Remember Me
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <p className="color text-danger text-center">{msg}</p>
                        </div>

                        <div className="form-group">
                          <button
                            type="submit"
                            className="color bg-dark text-white btn-lg btn-block"
                            tabIndex="4"
                            onClick={(e) => Auth(e)}
                          >
                            Login
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="col-7">
                      <img
                        src={loginImage}
                        alt="image"
                        className="img-fluid w-100 h-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
