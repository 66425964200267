import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SummerNoteJs from "../../../js/summernote";
import ToastrMessage from "../../../js/toastrMessage";
import axios from "axios";
import $ from "jquery";
import path, { dirname } from "path";
import ReactPaginate from "react-paginate";

const WnLawPortofolio = () => {
  const [userID, setUserID] = useState("");
  const [role, setRole] = useState([]);
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [portfolioURLs, setPortfolioURLs] = useState([]);
  const [usersIdToDelete, setUsersIdToDelete] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(portfolioItems.length / itemsPerPage);
  const startIndex = pageNumber * itemsPerPage;
  const displayedItems = portfolioItems.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  const API_URL = process.env.REACT_APP_API_BASE_URL;
  const imgPath = path.resolve(
    __dirname,
    "../../../../frontend/cms/public/assets/img"
  );

  const getUserIdFromLocalStorage = async () => {
    try {
      const user = localStorage.getItem("user");

      if (user) {
        const userData = JSON.parse(user);
        setUserID(userData.user.id);
      }
    } catch (error) {
      // console.log("Error fetching userID : ", error);
    }
  };

  const getUserRoleById = async (setRole) => {
    try {
      const response = await axios.get(`${API_URL}/users/${userID}`);

      setRole(response.data.role);
    } catch (error) {
      // console.log("Error fetching profile : ", error);
    }
  };

  const getPortofolios = async () => {
    try {
      const response = await axios.get(`${API_URL}/portofolios`);
      setPortfolioItems(response.data);
      console.log(response.data);
      console.log("success fetching portofolios");

      const urls = [];
      for (const portofolioItem of response.data) {
        if (portofolioItem.portofolio) {
          const imageURL = `${API_URL}${imgPath}/${portofolioItem.portofolio}`;
          urls.push(imageURL);
        } else {
          urls.push(null);
        }
      }
      setPortfolioURLs(urls);
    } catch (error) {
      console.log("Error fetching portofolio : ", error);
    }
  };

  const deletePortofolio = async (portofolioId) => {
    try {
      await axios.delete(`${API_URL}/portofolio/${portofolioId}`);
      getPortofolios();
      console.log("success deleting service: ");
    } catch (error) {
      console.log("Error deleting service: ", error);
    }
  };

  const handleConfirmDelete = () => {
    if (usersIdToDelete) {
      deletePortofolio(usersIdToDelete);
      setUsersIdToDelete(null);
      $("#exampleModal").modal("hide");
    }
  };

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    getUserIdFromLocalStorage();
  }, []);

  useEffect(() => {
    ToastrMessage();
    getPortofolios();
  }, [userID]);

  useEffect(() => {
    getUserRoleById(setRole);
    $("select").selectric();
    $.uploadPreview({
      input_field: "#image-upload",
      preview_box: "#image-preview",
      label_field: "#image-label",
      label_default: "Choose File",
      label_selected: "Change File",
      no_label: false,
      success_callback: null,
    });
    $(".inputtags").tagsinput("items");

    SummerNoteJs();
  }, [userID]);

  return (
    <div className="main-content">
      <section className="section">
        <div className="section-header">
          <h1>Portofolio</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <a href="/dashboard/general">Dashboard</a>
            </div>
            <div className="breadcrumb-item">
              <a href="#">Document</a>
            </div>
            <div className="breadcrumb-item">Portofolio</div>
          </div>
        </div>
        <div className="section-body">
          <h2 className="section-title">This is your photo portofolio.</h2>
          <div className="row mt-sm-4">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                  <h4>Your Portofolio</h4>
                  {role && role === "Admin" && (
                    <div className="col-auto">
                      <Link
                        to="/feature/wnlaw-add-portofolio"
                        className="btn btn-primary btn-lg d-flex align-items-center"
                        style={{ padding: "0.75rem" }}
                      >
                        <i className="fas fa-plus mr-2"></i>
                        Add Portofolio
                      </Link>
                    </div>
                  )}
                </div>
                <div className="card-body">
                  <div className="row" style={{ display: "flex" }}>
                    {displayedItems.map((item, index) => (
                      <div
                        key={index}
                        className="card"
                        style={{ marginRight: "14px", width: "300px" }}
                      >
                        <div className="card-body">
                          <div
                            className="chocolat-parent"
                            style={{ maxWidth: "300px" }}
                          >
                            <a
                              href={portfolioURLs[index + startIndex]}
                              className="chocolat-image"
                              title={item.title}
                            >
                              <img
                                alt={item.title}
                                src={portfolioURLs[index + startIndex]}
                                className="img-fluid"
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                }}
                              />
                            </a>
                          </div>
                        </div>
                        <div className="card-footer">
                          {role && role === "Admin" && (
                            <div className="text-center">
                              <Link
                                to={`/feature/wnlaw-edit-portofolio/${item.id}`}
                                className="btn btn-primary btn-action mr-1"
                                data-toggle="tooltip"
                                title="Edit"
                                onClick={() => {
                                  console.log(item.id);
                                }}
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </Link>
                              <button
                                className="btn btn-danger"
                                onClick={() => {
                                  setUsersIdToDelete(item.id);
                                  $("#exampleModal").modal("show");
                                }}
                              >
                                <i className="fas fa-trash"></i>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={
                    "pagination justify-content ml-3 mt-4 mr-5 mb-4"
                  }
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                  disabledClassName={"page-item disabled"}
                  activeClassName={"page-item active"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="modal fade" tabIndex="-1" role="dialog" id="exampleModal">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Do you really want to delete this item?{" "}
                <b>This action cannot be undone</b>.
              </p>
            </div>
            <div className="modal-footer bg-whitesmoke br">
              <button
                type="button"
                className="btn btn-danger btn-shadow"
                onClick={handleConfirmDelete}
                id="toastr-10"
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WnLawPortofolio;
