import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import SummerNoteJs from "../../js/summernote";
import $ from "jquery";
import axios from "axios";
import Select from "react-select";
import { FiEye, FiEyeOff } from "react-icons/fi";
// import AdvanceForm from "../../js/FormAdvanced";

const WnLawAddTeams = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [role, setRole] = useState("Staff");
  const [desc, setDesc] = useState("");
  const [office, setOffice] = useState("");
  const [phone, setPhone] = useState("");
  const [position, setPosition] = useState("");
  const [photoProfile, setphotoProfile] = useState(null);
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isValidDesc, setIsValidDesc] = useState(true);
  const [isValidOffice, setIsValidOffice] = useState(true);
  const [isValidPosition, setIsValidPosition] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [isValidConfPassword, setIsValidConfPassword] = useState(true);
  const [isValidRole, setIsValidRole] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [msg, setMsg] = useState("");
  const API_URL = process.env.REACT_APP_API_BASE_URL;
  const [isError, setIsError] = useState(false);
  const [isValidFileFormat, setIsValidFileFormat] = useState(true);
  const [validToastrId, setValidToastrId] = useState(true);

  const navigate = useNavigate();

  const options = [
    { value: "Admin", label: "Admin" },
    { value: "Staff", label: "Staff" },
  ];

  const saveUsers = async (e) => {
    e.preventDefault();

    if (photoProfile) {
      if (photoProfile.size > 1 * 1024 * 1024) {
        console.log("file.size : ", photoProfile.size);
        return;
      }
      if (photoProfile.type) {
        if (
          !["image/png", "image/jpeg", "image/jpg"].includes(photoProfile.type)
        ) {
          console.log("Unsupported file type");
          return;
        }
        handleFileChange(photoProfile);
      }
    }

    const formData = new FormData();

    formData.append("firstname", firstName);
    formData.append("lastname", lastName);
    formData.append("email", email);
    formData.append("desc", desc);
    formData.append("office", office);
    formData.append("position", position);
    formData.append("phone", phone);
    formData.append("password", password);
    formData.append("confPassword", confPassword);
    formData.append("role", role);
    formData.append("photoProfile", photoProfile);

    try {
      if (
        !firstName ||
        !lastName ||
        !email ||
        !password ||
        !confPassword ||
        !role ||
        !desc ||
        !office ||
        !phone ||
        !position ||
        !role
      ) {
        if (!firstName) {
          setIsValidFirstName(false);
          setValidToastrId(false);
        }
        if (!lastName) {
          setIsValidLastName(false);
          setValidToastrId(false);
        }

        if (!email) {
          setIsValidEmail(false);
          setValidToastrId(false);
        }
        if (!phone) {
          setIsValidPhone(false);
          setValidToastrId(false);
        }
        if (!desc) {
          setIsValidDesc(false);
          setValidToastrId(false);
        }
        if (!office) {
          setIsValidOffice(false);
          setValidToastrId(false);
        }
        if (!position) {
          setIsValidPosition(false);
          setValidToastrId(false);
        }
        if (!password) {
          setIsValidPassword(false);
          setValidToastrId(false);
        }
        if (!confPassword) {
          setIsValidConfPassword(false);
          setValidToastrId(false);
        }
        if (!role) {
          setIsValidRole(false);
          setValidToastrId(false);
        }

        return;
      }

      const response = await axios.post(`${API_URL}/users`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setMsg(response.data.msg);
      setIsError(false);
      setTimeout(() => {
        navigate("/form/teams", { replace: true });
      }, 1000);
    } catch (error) {
      console.log("Error fetching users : ", error);
      if (error.response && error.response.data && error.response.data.msg) {
        setMsg(error.response.data.msg);
        setIsError(true);
      } else {
        setMsg("An error occurred while saving the user.");
        setMsg(error.response.data.msg);
        setIsError(true);
      }
    }
  };

  const handleFileChange = (event) => {
    const isValidFormat = ["image/png", "image/jpeg", "image/jpg"].includes(
      event.type
    );

    const imagePreviewDiv = document.getElementById("image-preview");

    if (isValidFormat) {
      imagePreviewDiv.classList.remove("is-invalid");
    } else {
      imagePreviewDiv.classList.add("is-invalid");
    }

    imagePreviewDiv.removeAttribute("style");
  };

  const togglePasswordVisibility = (passwordType) => {
    switch (passwordType) {
      case "password":
        setShowPassword(!showPassword);
        break;
      case "confPassword":
        setShowConfPassword(!showConfPassword);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    $.uploadPreview({
      input_field: "#image-upload",
      preview_box: "#image-preview",
      label_field: "#image-label",
      label_default: "Choose File",
      label_selected: "Change File",
      no_label: false,
      success_callback: null,
    });
    $(".inputtags").tagsinput("items");

    SummerNoteJs();
    console.log("Role updated:", role);
  }, [role]);

  return (
    <div className="main-content">
      <section className="section">
        <div className="section-header">
          <div class="section-header-back">
            <Link to="/form/teams" class="btn btn-icon">
              <i class="fas fa-arrow-left"></i>
            </Link>
          </div>
          <h1>Add New Teams</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to="/dashboard/general">Dashboard</Link>
            </div>
            <div className="breadcrumb-item">
              <Link to="#">Document</Link>
            </div>
            <div className="breadcrumb-item">
              <Link to="/form/teams">Teams</Link>
            </div>
            <div className="breadcrumb-item">New Teams</div>
          </div>
        </div>
        <div className="section-body">
          <h1 className="section-title">
            Fill the information about your new team member on this page.
          </h1>

          <div className="row mt-sm-4">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="card">
                <form method="post" className="needs-validation" noValidate="">
                  <div className="card-header">
                    <h4>Edit Profile</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="form-group col-md-6 col-12">
                        First Name&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="ion-person"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            className={`form-control ${
                              !isValidFirstName ? "is-invalid" : ""
                            }`}
                            onChange={(event) => {
                              setFirstName(event.target.value);
                              setIsValidFirstName(true);
                            }}
                            value={firstName}
                            required
                          />
                          {!isValidFirstName && (
                            <div className="invalid-feedback">
                              Please fill in the first name
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-12">
                        Last Name&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="ion-person"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            className={`form-control ${
                              isValidLastName ? "" : "is-invalid"
                            }`}
                            onChange={(event) => {
                              setLastName(event.target.value);
                              setIsValidLastName(true);
                              setValidToastrId(true);
                            }}
                            value={lastName}
                            required
                          />
                          {!isValidLastName && (
                            <div className="invalid-feedback">
                              Please fill in the last name
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6 col-12">
                        Email&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="fas fa-envelope"></i>
                            </div>
                          </div>
                          <input
                            type="email"
                            className={`form-control ${
                              isValidEmail ? "" : "is-invalid"
                            }`}
                            placeholder="example@email.com"
                            required=""
                            value={email}
                            onChange={(event) => {
                              setEmail(event.target.value);
                              setIsValidEmail(true);
                              setValidToastrId(true);
                            }}
                          />
                          {!isValidEmail && (
                            <div className="invalid-feedback">
                              Please fill in the email
                            </div>
                          )}
                        </div>
                        {email && !email.includes("@") && (
                          <div className="text-danger">
                            Invalid email format !
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-6 col-12">
                        Phone&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="fas fa-phone"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            className={`form-control phone-number ${
                              isValidPhone ? "" : "is-invalid"
                            }`}
                            value={phone}
                            required
                            onChange={(event) => {
                              setPhone(event.target.value);
                              setIsValidPhone(true);
                              setValidToastrId(true);
                            }}
                          />
                          {!isValidPhone && (
                            <div className="invalid-feedback">
                              Please fill in the phone
                            </div>
                          )}
                        </div>
                        {phone && phone.length < 9 && (
                          <div className="text-danger">
                            Phone number must be at least 9 characters long !
                          </div>
                        )}
                        {phone && !/^\d+$/.test(phone) && (
                          <div className="text-danger">
                            Phone number must contain only numbers.
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6 col-12">
                        Office&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="ion-android-home"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            className={`form-control ${
                              isValidOffice ? "" : "is-invalid"
                            }`}
                            value={office}
                            required
                            onChange={(event) => {
                              setOffice(event.target.value);
                              setIsValidOffice(true);
                              setValidToastrId(true);
                            }}
                          />
                          {!isValidOffice && (
                            <div className="invalid-feedback">
                              Please fill in the office
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-12">
                        Position&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="ion-briefcase"></i>
                            </div>
                          </div>
                          <input
                            type="text"
                            className={`form-control ${
                              isValidPosition ? "" : "is-invalid"
                            }`}
                            value={position}
                            required
                            onChange={(event) => {
                              setPosition(event.target.value);
                              setIsValidPosition(true);
                              setValidToastrId(true);
                            }}
                          />
                          {!isValidPosition && (
                            <div className="invalid-feedback">
                              Please fill in the position
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6 col-12">
                        Password&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="fas fa-lock"></i>
                            </div>
                          </div>
                          <input
                            type={showPassword ? "text" : "password"}
                            className={`form-control pwstrength ${
                              isValidPassword ? "" : "is-invalid"
                            }`}
                            data-indicator="pwindicator"
                            value={password}
                            style={{ marginRight: "1px" }}
                            onChange={(event) => {
                              setPassword(event.target.value);
                              setIsValidPassword(true);
                              setValidToastrId(true);
                            }}
                          />
                          <div className="input-group-append">
                            <button
                              className={`btn ${
                                showPassword
                                  ? "btn-outline-primary"
                                  : "btn-outline-secondary input-group-text"
                              }`}
                              type="button"
                              onClick={() =>
                                togglePasswordVisibility("password")
                              }
                            >
                              {showPassword ? <FiEyeOff /> : <FiEye />}
                            </button>
                          </div>
                          {!isValidPassword && (
                            <div className="invalid-feedback">
                              Please fill in the position
                            </div>
                          )}
                        </div>
                        <div id="pwindicator" className="pwindicator">
                          <div className="bar"></div>
                          <div className="label"></div>
                        </div>
                        {password !== confPassword && (
                          <div className="text-danger">
                            Passwords do not match
                          </div>
                        )}
                        {password && password.length < 6 && (
                          <div className="text-danger">
                            Password must be at least 6 characters long !
                          </div>
                        )}
                        <div className="invalid-feedback">
                          Please fill in the Confirm Password
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-12">
                        Confirm Password&nbsp;
                        <span style={{ color: "red" }}>*</span>{" "}
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="fas fa-lock"></i>
                            </div>
                          </div>
                          <input
                            type={showConfPassword ? "text" : "password"}
                            className={`form-control pwstrength ${
                              isValidConfPassword ? "" : "is-invalid"
                            }`}
                            data-indicator="pwindicator"
                            value={confPassword}
                            style={{ marginRight: "1px" }}
                            onChange={(event) => {
                              setConfPassword(event.target.value);
                              setIsValidConfPassword(true);
                              setValidToastrId(true);
                            }}
                          />
                          <div className="input-group-append">
                            <button
                              className={`btn ${
                                showConfPassword
                                  ? "btn-outline-primary"
                                  : "btn-outline-secondary input-group-text"
                              }`}
                              type="button"
                              onClick={() =>
                                togglePasswordVisibility("confPassword")
                              }
                            >
                              {showConfPassword ? <FiEyeOff /> : <FiEye />}
                            </button>
                          </div>
                          {!isValidConfPassword && (
                            <div className="invalid-feedback">
                              Please fill in the confirm password
                            </div>
                          )}
                        </div>
                        {confPassword && confPassword.length < 6 && (
                          <div className="text-danger">
                            Password must be at least 6 characters long !
                          </div>
                        )}
                        <div id="pwindicator" className="pwindicator">
                          <div className="bar"></div>
                          <div className="label"></div>
                        </div>
                        <div className="invalid-feedback">
                          Please fill in the Confirm Password
                        </div>
                      </div>
                      <div className="form-group col-md-6 col-12">
                        Role&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <Select
                          options={options}
                          value={options.find(
                            (option) => option.value === role
                          )}
                          required
                          onChange={(selectedOption) => {
                            setRole(selectedOption.value);
                            setIsValidRole(true);
                            setValidToastrId(true);
                          }}
                        />
                        {!isValidRole && (
                          <div className="invalid-feedback">
                            Please fill in the role
                          </div>
                        )}
                      </div>

                      <div className="form-group col-md-12 col-12">
                        Description&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <textarea
                          className={`form-control ${
                            isValidDesc ? "" : "is-invalid"
                          }`}
                          style={{ minHeight: "300px", resize: "none" }}
                          value={desc}
                          onChange={(event) => {
                            setDesc(event.target.value);
                            setIsValidDesc(true);
                            setValidToastrId(true);
                          }}
                          rows={10}
                        ></textarea>
                        {!isValidDesc && (
                          <div className="invalid-feedback">
                            Please fill in the description
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6 col-12">
                        <label>Photo Profile</label>
                        <div id="image-preview" className="image-preview">
                          <label for="image-upload" id="image-label">
                            Choose File
                          </label>
                          <input
                            type="file"
                            name="image"
                            id="image-upload"
                            onChange={(event) => {
                              setphotoProfile(event.target.files[0]);
                              handleFileChange(event);
                              console.log(
                                "photoProfile:",
                                event.target.files[0]
                              );
                            }}
                          />
                        </div>
                        <div className="text">
                          Limit size of the photo is 10 MB.
                        </div>
                        {photoProfile &&
                          !["image/png", "image/jpeg", "image/jpg"].includes(
                            photoProfile.type
                          ) && (
                            <div className="invalid-feedback">
                              Please select a PNG, JPG, or JPEG file.
                            </div>
                          )}

                        {photoProfile &&
                          photoProfile.size > 1 * 1024 * 1024 && (
                            <div className="invalid-feedback">
                              File size should not exceed 10MB.
                            </div>
                          )}
                      </div>
                    </div>
                    {isError ? (
                      <div className="text-left color text-danger">{msg}</div>
                    ) : (
                      <div className="text-left color text-success">{msg}</div>
                    )}
                  </div>
                  <div className="card-footer text-right">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="toastr-9"
                      data-toggle="modal"
                      onClick={(e) => saveUsers(e)}
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="modal fade" tabIndex="-1" role="dialog" id="exampleModal">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Save Data</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Do you want to save the changes?</p>
            </div>
            <div className="modal-footer bg-whitesmoke br">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={(e) => saveUsers(e)}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default WnLawAddTeams;
