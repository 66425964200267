import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ToastrMessage from "../../js/toastrMessage";
import axios from "axios";
import Select from "react-select";

const WNLawEditServices = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [category, setCategory] = useState("");
  const [isValidTitle, setIsValidTitle] = useState(true);
  const [isValidContent, setIsValidContent] = useState(true);
  const [isValidCategory, setIsValidCategory] = useState(true);
  const API_URL = process.env.REACT_APP_API_BASE_URL;
  const [msg, setMsg] = useState("");
  const [isError, setIsError] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const options = [
    { value: "Litigation", label: "Litigation" },
    {
      value: "Non Litigation And Legal Counseling",
      label: "Non Litigation And Legal Counseling",
    },
    {
      value: "Alternative Dispute Resolution",
      label: "Alternative Dispute Resolution",
    },
  ];

  const getServicesById = async (setTitle, setContent, setCategory) => {
    try {
      const response = await axios.get(`${API_URL}/services/${id}`);
      setTitle(response.data.title);
      setContent(response.data.content);
      setCategory(response.data.category);
      // $('.summernote-simple').summernote('pasteHTML', response.data.content);
    } catch (error) {
      console.log("Error fetching services : ", error);
      setMsg(error.response.data.msg);
      setIsError(true);
    }
  };

  const updateServices = async (e) => {
    e.preventDefault();

    try {
      if (!title || !content || !category) {
        if (!title) {
          setIsValidTitle(false);
          // setValidToastrId(false);
        }
        if (!content) {
          setIsValidContent(false);
          // setValidToastrId(false);
        }

        if (!category) {
          setIsValidCategory(false);
          // setValidToastrId(false);
        }
        return;
      }

      console.log(`Updating services with ID ${id}...`);

      const response = await axios.patch(`${API_URL}/services/${id}`, {
        title: title,
        content: content,
        category: category,
      });
      console.log("Patch response:", response.data);

      setMsg(response.data.msg);
      setIsError(false);
      setTimeout(() => {
        navigate("/form/services", { replace: true });
      }, 1000);
    } catch (error) {
      setMsg("Error save services : ", error);
      if (error.response && error.response.data && error.response.data.msg) {
        setMsg(error.response.data.msg);
        setIsError(true);
      } else {
        setMsg("An error occurred while saving the services.");
        setIsError(true);
      }
    }
  };

  useEffect(() => {
    // ToastrMessage();

    getServicesById(setTitle, setContent, setCategory);

    // $('.summernote-simple').on('summernote.change', function(we, contents, $editable) {
    //   setContent(contents)
    // })

    // $("#exampleModal").on("shown.bs.modal", function() {
    //   updateServices();
    // });
  }, []);

  return (
    <div class="main-content">
      <section class="section">
        <div class="section-header">
          <div class="section-header-back">
            <Link to="/form/services" class="btn btn-icon">
              <i class="fas fa-arrow-left"></i>
            </Link>
          </div>
          <h1>Edit Services</h1>
          <div class="section-header-breadcrumb">
            <div class="breadcrumb-item active">
              <a href="/dashboard/general">Dashboard</a>
            </div>
            <div class="breadcrumb-item">
              <Link to="#">Document</Link>
            </div>
            <div class="breadcrumb-item">Edit Services</div>
          </div>
        </div>
        <div class="section-body">
          <h2 class="section-title">{title}</h2>
          <p class="section-lead">
            Change information about yourself on this page.
          </p>

          <div class="row mt-sm-4">
            <div class="col-12 col-md-12 col-lg-12">
              <div class="card">
                <form class="needs-validation" novalidate="">
                  <div class="card-header">
                    <h4>Edit Services</h4>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="form-group col-md-6 col-12">
                        Title&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <input
                          type="text"
                          className={`form-control ${
                            !isValidTitle ? "is-invalid" : ""
                          }`}
                          onChange={(event) => {
                            setTitle(event.target.value);
                            setIsValidTitle(true);
                          }}
                          required=""
                          value={title}
                        />
                        {!isValidTitle && (
                          <div class="invalid-feedback">
                            Please fill in the title
                          </div>
                        )}
                      </div>
                      <div class="form-group col-md-6 col-12">
                        Category&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <div>
                          <Select
                            options={options}
                            value={options.find(
                              (option) => option.value === category
                            )}
                            onChange={(selectedOption) => {
                              setCategory(selectedOption.value);
                              setIsValidCategory(true);
                            }}
                          />
                          {!isValidCategory && (
                            <div class="invalid-feedback">
                              Please fill in the category
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-12 col-12">
                        Description&nbsp;<span style={{ color: "red" }}>*</span>{" "}
                        <textarea
                          className={`form-control ${
                            !isValidContent ? "is-invalid" : ""
                          }`}
                          style={{ minHeight: "300px", resize: "none" }}
                          value={content}
                          onChange={(event) => {
                            setContent(event.target.value);
                            setIsValidContent(true);
                          }}
                          rows={10}
                        ></textarea>
                        {/* Show validation error message when necessary */}
                        {/* Assuming you want to validate the content */}
                        {/* Adjust the condition accordingly */}
                        {!isValidContent && (
                          <div className="invalid-feedback">
                            Please fill in the description.
                          </div>
                        )}
                      </div>
                    </div>
                    {isError ? (
                      <div className="text-left color text-danger">{msg}</div>
                    ) : (
                      <div className="text-left color text-success">{msg}</div>
                    )}
                  </div>
                  <div class="card-footer text-right">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      id="toastr-9"
                      data-toggle="modal"
                      onClick={(e) => updateServices(e)}
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
                {/* <div class="card-footer text-right">
                    <button
                      className="btn btn-primary"
                      data-toggle="modal"
                      // data-target="#exampleModal"
                    >
                      Save Changes
                    </button>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="modal fade" tabIndex="-1" role="dialog" id="exampleModal">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Modal title</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Modal body text goes here.</p>
            </div>
            <div className="modal-footer bg-whitesmoke br">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                // data-dismiss="modal"
                onClick={(e) => updateServices(e)}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WNLawEditServices;
