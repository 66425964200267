import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const isAuthenticated = () => {
    try {
      const user = localStorage.getItem("user");
      return user !== null && JSON.parse(user).isSuccess;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  return isAuthenticated() ? <Outlet /> : <Navigate to="/" replace />;
};

export default ProtectedRoute;
