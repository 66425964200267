import React from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOut, reset } from "../../../pages/Pages/Features/auth-slice";

const UserDropdown = ({ userDetail }) => {
  const dispatch = useDispatch();
  const API_URL = process.env.REACT_APP_API_BASE_URL;

  const logoutFunction = async () => {
    try {
      const response = await axios.delete(`${API_URL}/logout`);
      console.log(response);
      dispatch(logOut());
      dispatch(reset());
      window.location.href = "/";
    } catch (error) {
      console.log("Error fetching services: ", error);
    }
  };

  return (
    <li className="dropdown">
      <a
        href="#"
        data-toggle="dropdown"
        className="nav-link dropdown-toggle nav-link-lg nav-link-user"
      >
        <img
          alt="image"
          src={userDetail.userImg}
          className="rounded-circle mr-1"
        />
        <div className="d-sm-none d-lg-inline-block">
          Hi, {userDetail.userName}
        </div>
      </a>
      <div className="dropdown-menu dropdown-menu-right">
        {userDetail.datas.map((data, idata) => (
          <NavLink
            key={idata}
            to={data.link}
            activeStyle={{
              color: "#6777ef",
            }}
            exact
            className="dropdown-item has-icon"
          >
            <i className={data.icode} /> {data.title}
          </NavLink>
        ))}
        <div className="dropdown-divider" />
        <a
          href="#"
          className="dropdown-item has-icon text-danger"
          onClick={logoutFunction}
        >
          <i className={userDetail.logoutIcon} /> {userDetail.logoutTitle}
        </a>
      </div>
    </li>
  );
};

export default UserDropdown;
