import React, { useEffect, useState } from "react";
import axios from "axios";
import $ from "jquery";
import ToastrMessage from "../../js/toastrMessage";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

const WNLawServices = () => {
  const [userID, setUserID] = useState("");
  const [role, setRole] = useState([]);
  const [services, setServices] = useState([]);
  const [serviceIdToDelete, setServiceIdToDelete] = useState(null);
  const API_URL = process.env.REACT_APP_API_BASE_URL;
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 5;
  const pageCount = Math.ceil(services.length / itemsPerPage);
  const startIndex = pageNumber * itemsPerPage;
  const displayedItems = services.slice(startIndex, startIndex + itemsPerPage);

  const getUserIdFromLocalStorage = async () => {
    try {
      const user = localStorage.getItem("user");

      if (user) {
        const userData = JSON.parse(user);
        setUserID(userData.user.id);
      }
    } catch (error) {
      // console.log("Error fetching userID : ", error);
    }
  };

  const getUserRoleById = async (setRole) => {
    try {
      const response = await axios.get(`${API_URL}/users/${userID}`);
      setRole(response.data.role);
      console.log("Role : ", response.data.role);
    } catch (error) {
      // console.log("Error fetching profile : ", error);
    }
  };

  const getServices = async () => {
    try {
      const response = await axios.get(`${API_URL}/services`);
      setServices(response.data);
      console.log(response.data);
    } catch (error) {
      console.log("Error fetching services: ", error);
    }
  };

  const deleteService = async (serviceId) => {
    try {
      await axios.delete(`${API_URL}/services/${serviceId}`);
      getServices(); // Refresh the service list after deletion
    } catch (error) {
      console.log("Error deleting service: ", error);
    }
  };

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleConfirmDelete = () => {
    if (serviceIdToDelete) {
      deleteService(serviceIdToDelete); // Delete the service
      setServiceIdToDelete(null); // Reset the service ID to delete
      $("#exampleModal").modal("hide"); // Hide the modal after deletion
    }
  };

  useEffect(() => {
    getUserIdFromLocalStorage();
  }, []);

  useEffect(() => {
    getUserRoleById(setRole);
    getServices();
    ToastrMessage();
  }, [userID]);

  return (
    <div className="main-content">
      <section className="section">
        <div className="section-header">
          <h1>Services</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <a href="/dashboard/general">Dashboard</a>
            </div>
            <div className="breadcrumb-item">
              <a href="#">Document</a>
            </div>
            <div className="breadcrumb-item">Services</div>
          </div>
        </div>

        <h2 className="section-title">Services Table</h2>
        <p className="section-lead">Your service detailed here.</p>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h4>Details</h4>
                {role && role === "Admin" && (
                  <div className="col-auto">
                    <Link
                      to="/form/add-services"
                      className="btn btn-primary btn-lg d-flex align-items-center"
                      style={{ padding: "0.75rem" }}
                    >
                      <i className="fas fa-plus mr-2"></i>
                      Add Service
                    </Link>
                  </div>
                )}
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <tr>
                      <th>No</th>
                      <th>Title</th>
                      <th>Category</th>
                      <th>Description</th>
                      {role && role === "Admin" && (
                        <th className="text-center col-2">Action</th>
                      )}
                    </tr>

                    <tbody>
                      {displayedItems.map((service, index) => (
                        <tr key={service.id}>
                          <th scope="row">{startIndex + index + 1}</th>
                          <td>
                            <Link
                              to={`/form/edit-services/${service.id}`}
                              className="font-weight-600"
                            >
                              {service.title}
                            </Link>
                          </td>
                          <td>
                            <a className="font-weight-600">
                              {service.category}
                            </a>
                          </td>
                          <td>
                            {service.content.length > 300
                              ? `${service.content.substring(0, 300)}...`
                              : service.content}
                          </td>
                          {role && role === "Admin" && (
                            <td className="text-center">
                              <Link
                                to={`/form/edit-services/${service.id}`}
                                className="btn btn-primary btn-action mr-1"
                                data-toggle="tooltip"
                                title="Edit"
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </Link>
                              <button
                                className="btn btn-danger"
                                onClick={() => {
                                  setServiceIdToDelete(service.id);
                                  $("#exampleModal").modal("show");
                                }}
                              >
                                <i className="fas fa-trash"></i>
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={
                  "pagination justify-content ml-3 mt-4 mr-5 mb-4"
                }
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                disabledClassName={"page-item disabled"}
                activeClassName={"page-item active"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
              />
            </div>
          </div>
        </div>
      </section>

      {/* Modal for confirmation */}
      <div className="modal fade" tabIndex="-1" role="dialog" id="exampleModal">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Do you really want to delete this item?{" "}
                <b>This action cannot be undone</b>.
              </p>
            </div>
            <div className="modal-footer bg-whitesmoke br">
              <button
                type="button"
                className="btn btn-danger btn-shadow"
                onClick={handleConfirmDelete}
                id="toastr-10"
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WNLawServices;
