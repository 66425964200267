import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  user: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

const API_URL = process.env.REACT_APP_API_BASE_URL;

// Fungsi untuk menyimpan data otentikasi ke local storage
const saveAuthToLocalStorage = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

// Fungsi untuk mengambil data otentikasi dari local storage
const getAuthFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("user")) || null;
};

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (user, thunkAPI) => {
    try {
      const response = await axios.post(`${API_URL}/login`, {
        email: user.email,
        password: user.password,
      });
      // Simpan data otentikasi ke local storage setelah berhasil login
      console.log("Response dari auth-slice : ", response.data);
      // saveAuthToLocalStorage(response.data);
      return response.data;
    } catch (error) {
      if (error.response) {
        const message = error.response.data.msg;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const getUserById = createAsyncThunk(
  "user/getuserlogin",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`${API_URL}/getuserlogin`);
      return response.data;
    } catch (error) {
      if (error.response) {
        const message = error.response.data.msg;
        return thunkAPI.rejectWithValue(message);
      }
    }
  }
);

export const logOut = createAsyncThunk("user/logOut", async () => {
  localStorage.removeItem("user");

  await axios.delete(`${API_URL}/logout`);
});

export const authSlice = createSlice({
  name: "auth",
  initialState: getAuthFromLocalStorage() || initialState, 
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.user = action.payload;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });

    //Get User Login
    builder.addCase(getUserById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.user = action.payload;
    });
    builder.addCase(getUserById.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    });

    // Logout
    builder.addCase(logOut.fulfilled, (state) => {
      state.user = null;
    });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
