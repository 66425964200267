import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import SummerNoteJs from "../../../js/summernote";
import ToastrMessage from "../../../js/toastrMessage";
import axios from "axios";
import $ from "jquery";

const WNLawAddPortofolio = () => {
  const [userID, setUserID] = useState("");
  const [portfolioItems, setPortfolioItems] = useState([]);
  const API_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const updateProfile = async (e) => {
    e.preventDefault();

    if (portfolioItems) {
      if (portfolioItems.size > 1 * 1024 * 1024) {
        console.log("file.size : ", portfolioItems.size);
        return;
      }
      handleFileChange(portfolioItems);
    }

    try {
      const formData = new FormData();

      formData.append("photoProfile", portfolioItems);

      console.log(formData);

      await axios.post(`${API_URL}/portofolio`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      navigate("/feature/wnlaw-portofolio", { replace: true });
    } catch (error) {
      console.log("Error adding portofolio:", error);
    }
  };

  const handleFileChange = (event) => {
    const isValidFormat = ["image/png", "image/jpeg", "image/jpg"].includes(
      event.type
    );

    const imagePreviewDiv = document.getElementById("image-preview");

    if (isValidFormat) {
      imagePreviewDiv.classList.remove("is-invalid");
    } else {
      imagePreviewDiv.classList.add("is-invalid");
    }

    imagePreviewDiv.removeAttribute("style");
  };

  useEffect(() => {
    ToastrMessage();
  }, []);

  useEffect(() => {
    $("select").selectric();
    $.uploadPreview({
      input_field: "#image-upload",
      preview_box: "#image-preview",
      label_field: "#image-label",
      label_default: "Choose File",
      label_selected: "Change File",
      no_label: false,
      success_callback: null,
    });
    $(".inputtags").tagsinput("items");

    SummerNoteJs();
  }, [userID]);

  return (
    <div className="main-content">
      <section className="section">
        <div className="section-header">
          <h1>Portofolio</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to="/dashboard/general">Dashboard</Link>
            </div>
            <div className="breadcrumb-item">
              <a href="#">Document</a>
            </div>
            <div className="breadcrumb-item active">
              <Link to="/feature/wnlaw-portofolio">Portofolio</Link>
            </div>
            <div className="breadcrumb-item">Add Portofolio</div>
          </div>
        </div>
        <div className="section-body">
          <p className="section-title">Insert your photo portofolio here</p>

          <div className="row mt-sm-4">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="card">
                <form method="post" className="needs-validation" noValidate="">
                  <div className="card-header">
                    <h4>Add Portofolio</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="form-group col-md-6 col-12">
                        Portofolio&nbsp;
                        <span style={{ color: "red" }}>*</span>{" "}
                        <div id="image-preview" className="image-preview">
                          <label for="image-upload" id="image-label">
                            Choose File
                          </label>
                          <input
                            type="file"
                            name="image"
                            id="image-upload"
                            onChange={(event) => {
                              setPortfolioItems(event.target.files[0]);
                              handleFileChange(event);
                              console.log(
                                "portfolioItems:",
                                event.target.files[0]
                              );
                            }}
                          />
                        </div>
                        <div className="text">
                          Limit size of the photo is 10 MB.
                        </div>
                        {portfolioItems &&
                          !["image/png", "image/jpeg", "image/jpg"].includes(
                            portfolioItems.type
                          ) && (
                            <div className="invalid-feedback">
                              Please select a PNG, JPG, or JPEG file.
                            </div>
                          )}
                        {portfolioItems &&
                          portfolioItems.size > 1 * 1024 * 1024 && (
                            <div className="invalid-feedback">
                              File size should not exceed 10MB.
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer text-right">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={(e) => updateProfile(e)} // Memastikan bahwa Anda meneruskan objek event (e)
                      // id={validToastrId ? "toastr-9" : "toastr-11"}
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WNLawAddPortofolio;
