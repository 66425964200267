import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";

const WNLawDashboard = () => {
  const [users, setUsers] = useState([]);
  const [services, setServices] = useState([]);
  const [totalAdmin, setTotalAdmin] = useState([]);
  const [totalTeam, setTotalTeam] = useState([]);
  const [totalServices, setTotalServices] = useState([]);
  const API_URL = process.env.REACT_APP_API_BASE_URL;
  const [teamsPageNumber, setTeamsPageNumber] = useState(0);
  const [servicesPageNumber, setServicesPageNumber] = useState(0);
  const itemsPerPage = 5;
  const displayedItemsTeams = users.slice(
    teamsPageNumber * itemsPerPage,
    (teamsPageNumber + 1) * itemsPerPage
  );

  const displayedItemsServices = services.slice(
    servicesPageNumber * itemsPerPage,
    (servicesPageNumber + 1) * itemsPerPage
  );

  const pageCountTeams = Math.ceil(users.length / itemsPerPage);
  const pageCountServices = Math.ceil(services.length / itemsPerPage);

  const navigate = useNavigate();
  const isAuthenticated = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("User dari local storage:", user);
    return user !== null;
  };

  useEffect(() => {
    getUsers(setUsers);

    getServices(setServices);

    if (!isAuthenticated()) {
      navigate("/");
    }
  }, [navigate]);

  const getUsers = async (setUsers) => {
    try {
      const response = await axios.get(`${API_URL}/users`);
      setUsers(response.data);

      const adminUsers = response.data.filter((user) => user.role === "Admin");
      setTotalAdmin(adminUsers.length);

      const totalUsers = response.data.length;
      setTotalTeam(totalUsers);
    } catch (error) {
      console.log("Error fetching users : ", error);
    }
  };

  const getServices = async (setServices) => {
    try {
      const responseServices = await axios.get(`${API_URL}/services`);
      setServices(responseServices.data);

      const totalServices = responseServices.data.length;
      setTotalServices(totalServices);
    } catch (error) {
      console.log("Error fetching services : ", error);
    }
  };

  const changeTeamsPage = ({ selected }) => {
    setTeamsPageNumber(selected);
  };

  const changeServicesPage = ({ selected }) => {
    setServicesPageNumber(selected);
  };

  return (
    <div className="main-content">
      <section className="section">
        <div className="section-header">
          <h1>Dashboard</h1>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="card card-statistic-1">
              <div className="card-icon bg-primary">
                <i className="far fa-user"></i>
              </div>
              <div className="card-wrap">
                <div className="card-header">
                  <h4>Total Admin</h4>
                </div>
                <div className="card-body">{totalAdmin}</div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="card card-statistic-1">
              <div className="card-icon bg-success">
                <i className="far fa-user"></i>
              </div>
              <div className="card-wrap">
                <div className="card-header">
                  <h4>Total Team</h4>
                </div>
                <div className="card-body">{totalTeam}</div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="card card-statistic-1">
              <div className="card-icon bg-warning">
                <i className="far fa-newspaper"></i>
              </div>
              <div className="card-wrap">
                <div className="card-header">
                  <h4>Total Services</h4>
                </div>
                <div className="card-body">{totalServices}</div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="card card-statistic-1">
                <div className="card-icon bg-warning">
                  <i className="far fa-file"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header">
                    <h4>Reports</h4>
                  </div>
                  <div className="card-body">1,201</div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="card card-statistic-1">
                <div className="card-icon bg-success">
                  <i className="fas fa-circle"></i>
                </div>
                <div className="card-wrap">
                  <div className="card-header">
                    <h4>Online Users</h4>
                  </div>
                  <div className="card-body">47</div>
                </div>
              </div>
            </div> */}
        </div>
        <h2 className="section-title">Team Table</h2>
        <p className="section-lead">Our team detailed here.</p>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table table-striped mb-0">
                    <tr>
                      <th>No</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Phone</th>
                      <th>Position</th>
                    </tr>

                    <tbody>
                      {displayedItemsTeams.map((user, index) => (
                        <tr key={user.id}>
                          <th scope="row">
                            {teamsPageNumber * itemsPerPage + index + 1}
                          </th>
                          <td>
                            <a className="font-weight-600">
                              <div className="d-flex align-items-center">
                                <img
                                  src="../assets/img/avatar/avatar-1.png"
                                  alt="avatar"
                                  width="30"
                                  className="rounded-circle mr-3"
                                />
                                <div>
                                  <p className="font-weight-600 m-0">
                                    {user.firstname} {user.lastname}
                                  </p>
                                </div>
                              </div>
                            </a>
                          </td>
                          <td>
                            <p>
                              {user.desc.length > 300
                                ? `${user.desc.substring(0, 300)}...`
                                : user.desc}
                              {/* <div className="table-links">
                                in{" "}
                                <a href="https://wnlaw.net/home">
                                  {user.office}
                                </a>
                                <div className="bullet"></div>
                                <a href="#">View</a>
                              </div> */}
                            </p>
                          </td>
                          <td>
                            <p className="font-weight-600">{user.phone}</p>
                          </td>
                          <td>
                            <p className="font-weight-600">{user.position}</p>
                          </td>
                          {/* <td>
                            <a
                              className="btn btn-primary btn-action mr-1"
                              data-toggle="tooltip"
                              title="Edit"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </a>
                            <a
                              className="btn btn-danger btn-action"
                              data-toggle="tooltip"
                              title="Delete"
                              data-confirm="Are You Sure?|This action can not be undone. Do you want to continue?"
                              data-confirm-yes="alert('Deleted')"
                            >
                              <i className="fas fa-trash"></i>
                            </a>
                          </td> */}
                        </tr>
                      ))}
                      {/* <tr>
                        <td>
                          <a href="#" className="font-weight-600">
                            <img
                              src="../assets/img/avatar/avatar-1.png"
                              alt="avatar"
                              width="30"
                              className="rounded-circle mr-1"
                            />{" "}
                            Administrator
                          </a>
                        </td>
                        <td>
                          Introduction Laravel 5
                          <div className="table-links">
                            in <a href="#">Web Development</a>
                            <div className="bullet"></div>
                            <a href="#">View</a>
                          </div>
                        </td>
                        <td>
                          <p className="font-weight-600">Kepala Cabang</p>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCountTeams}
                onPageChange={changeTeamsPage}
                containerClassName={
                  "pagination justify-content ml-3 mt-4 mr-5 mb-4"
                }
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                disabledClassName={"page-item disabled"}
                activeClassName={"page-item active"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
              />
            </div>
          </div>
        </div>
        <h2 className="section-title">Service Table</h2>
        <p className="section-lead">Your services detailed here.</p>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12 col-sm-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Title</th>
                        <th>Category</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedItemsServices.map((service, index) => (
                        <tr key={service.id}>
                          <th scope="row">{index + 1}</th>
                          <td>
                            <p></p>
                            <a className="font-weight-600">{service.title}</a>
                          </td>
                          <td>
                            <p>
                              <a className="font-weight-600">
                                {service.category}
                              </a>
                            </p>
                          </td>
                          <td>
                            <p>
                              {service.content.length > 300
                                ? `${service.content.substring(0, 300)}...`
                                : service.content}
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCountServices}
                onPageChange={changeServicesPage}
                containerClassName={
                  "pagination justify-content ml-3 mt-4 mr-5 mb-4"
                }
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                disabledClassName={"page-item disabled"}
                activeClassName={"page-item active"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WNLawDashboard;
