import React, { Component } from "react";
import image from "../../../assets/img/logo_wnlaw_big.jpg";

export class WNLawForgotPassword extends Component {
  render() {
    return (
      <div id="app">
        <section class="section">
          <div class="row">
            <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
              <div class="login-brand">
                <img
                  src={image}
                  alt="logo"
                  width="100"
                  class="shadow-light  rounded-box "
                />
              </div>
              <div class="form-group col-12 col-sm-3">
                <button
                  className="btn btn-primary btn-lg btn-block d-flex align-items-center"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  {" "}
                  <i className="fas fa-arrow-left mr-2"></i> Back
                </button>
                {/* <a
                  href="/dashboard/general"
                  className="btn btn-primary btn-lg btn-block d-flex align-items-center"
                >
                  <i className="fas fa-arrow-left mr-2"></i> Back
                </a> */}
              </div>
              <div class="container mt-0">
                <div class="section-header-back">
                  {/* <a href="/form/teams" class="btn btn-icon">
                    <i class="fas fa-arrow-left"></i>
                  </a> */}
                </div>
                <div class="card card-primary">
                  <div class="card-header">
                    <h4>Forgot Password</h4>
                  </div>

                  <div class="card-body">
                    <p class="text-muted">
                      Your password will be immediately reset
                    </p>
                    <form method="POST">
                      <div class="form-group">
                        <label>Email</label>

                        <input
                          id="email"
                          type="email"
                          class="form-control pwstrength"
                          data-indicator="pwindicator"
                          name="password"
                          tabindex="2"
                          required
                        />
                        <div id="pwindicator" class="pwindicator">
                          <div class="bar"></div>
                          <div class="label"></div>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="password">New Password</label>
                        <input
                          id="password"
                          type="password"
                          class="form-control pwstrength"
                          data-indicator="pwindicator"
                          name="password"
                          tabindex="2"
                          required
                        />
                        <div id="pwindicator" class="pwindicator">
                          <div class="bar"></div>
                          <div class="label"></div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="password-confirm">Confirm Password</label>
                        <input
                          id="password-confirm"
                          type="password"
                          class="form-control"
                          name="confirm-password"
                          tabindex="2"
                          required
                        />
                      </div>

                      <div class="form-group">
                        <button
                          type="submit"
                          class="btn btn-primary btn-lg btn-block"
                          tabindex="4"
                        >
                          Reset Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="simple-footer">Copyright &copy; WNLaw 2024</div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          id="exampleModal"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Data not saved</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Data not saved yet, do you want to continue?</p>
              </div>
              <div className="modal-footer bg-whitesmoke br">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <a href="/" className="btn btn-primary ">
                  Yes
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WNLawForgotPassword;
