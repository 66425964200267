import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../pages/Pages/Features/auth-slice";

const saveToLocalStorage = (state) => {
  try {
    const userId = state.user?.id;
    const userWithId = { id: userId };
    const newState = { ...state, user: userWithId };
    const serializedState = JSON.stringify(newState);
    localStorage.setItem("user", serializedState);
  } catch (e) {
    console.log(e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("user");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const store = configureStore({
  reducer: {
    auth: authReducer,
  },
  preloadedState: loadFromLocalStorage(),
});

store.subscribe(() => {
  saveToLocalStorage(store.getState().auth);
});
