import React from "react";

export default function Footer() {
  return (
    <footer className="main-footer">
      <div className="footer-left">
        <div className="simple-footer">Copyright WnLaw 2024 </div>
        {/* <a href="https://nauval.in/"> Muhamad Nauval Azhar </a>{" "} */}
      </div>{" "}
      {/* <div className="footer-right"> 2.3 .0 </div>{" "} */}
    </footer>
  );
}
