import $ from "jquery";
import iziToast from "izitoast";

export default function ToastrMessage() {
  $("#toastr-1").click(function() {
    iziToast.info({
      title: "Hello, world!",
      message: "This awesome plugin is made iziToast toastr",
      position: "topRight",
    });
  });

  $("#toastr-2").click(function() {
    iziToast.success({
      title: "Hello, world!",
      message: "This awesome plugin is made by iziToast",
      position: "topRight",
    });
  });

  $("#toastr-3").click(function() {
    iziToast.warning({
      title: "Hello, world!",
      message: "This awesome plugin is made by iziToast",
      position: "topRight",
    });
  });

  $("#toastr-4").click(function() {
    iziToast.error({
      title: "Hello, world!",
      message: "This awesome plugin is made by iziToast",
      position: "topRight",
    });
  });

  $("#toastr-5").click(function() {
    iziToast.show({
      title: "Hello, world!",
      message: "This awesome plugin is made by iziToast",
      position: "bottomRight",
    });
  });

  $("#toastr-6").click(function() {
    iziToast.show({
      title: "Hello, world!",
      message: "This awesome plugin is made by iziToast",
      position: "bottomCenter",
    });
  });

  $("#toastr-7").click(function() {
    iziToast.show({
      title: "Hello, world!",
      message: "This awesome plugin is made by iziToast",
      position: "bottomLeft",
    });
  });

  $("#toastr-8").click(function() {
    iziToast.show({
      title: "Hello, world!",
      message: "This awesome plugin is made by iziToast",
      position: "topCenter",
    });
  });
  $("#toastr-9").click(function() {
    iziToast.success({
      title: "Saved Successfully!",
      message: "Your data has been saved",
      position: "bottomRight",
    });
  });
  $("#toastr-10").click(function() {
    iziToast.error({
      title: "Delete Successfully!",
      message: "Your data has been deleted",
      position: "bottomRight",
    });
  });
  $("#toastr-11").click(function() {
    iziToast.warning({
      title: "Field is not fully fullfield!",
      message: "Please fill the field first before saving",
      position: "bottomRight",
    });
  });
}
